<template>
	<div>
		<Mask></Mask>
		<div class="box">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">协议列表</div>
			<ul>
				<li @click="choose(index)" v-for="(item,index) in menuList" :class="{'choose':index==chooseNum}">
					{{item.name}}
				</li>
			</ul>
			<button @click="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";
	// 引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "ModeBox",
		data() {
			return {
				menuList: '',
				xyID:'',
				chooseNum: 0, // 默认选中
				page:1,
				mmetType:'', // 类型id
				sortID:this.sortID,
				meetType:this.meetType,
			}
		},
		props:['meetType','sortID'],
		components: {
			Mask
		},
		created() {
			
			this.getListInfo()
			
			console.log(99,this.meetType)
		},
		methods: {
			// 选中
			choose(_ind) {
				this.chooseNum = _ind
				this.xyID = this.menuList[this.chooseNum].id;
			},
			// 关闭
			close(){
				// this.show = false
				this.$emit('close', {
					show:false,
					state:1,
				});
			},
			// 请求协议列表
			getListInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					page: this.page

				}
				let a = qs.stringify(obj)
				// console.log(a)

				axios.post(API_LIST.agreement_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)

						if (_d.data.status == 200) {
							this.menuList = _d.data.list;
							this.xyID = _d.data.list[0].id;
							// console.log(this.menuList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 确定
			submit() {
				if(this.xyID){		
					// this.$router.push({
					// 	path: '/MeetWrite',
					// 	query: {
					// 		xyID:this.xyID
					// 	}
					// });
					this.add_department()
				}else{
					ElMessage({
						message: '请选择协议',
						type: 'warning',
					})
				}
			},
			// 创建科室会
			add_department(){
				let obj = {
					u_id: localStorage.getItem("u_id"),
					type: this.meetType,
					agreement_id:this.xyID,
					memory_id:this.sortID
				}
				let a = qs.stringify(obj)
				console.log(a)
				
				axios.post(API_LIST.add_department, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
				
						if (_d.data.status == 200) {
							if(this.sortID == 1){
								this.$router.push({
									path: '/MeetWrite',
									query: {
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}else if(this.sortID == 3){
								this.$router.push({
									path: '/MeetWriteChan',
									query: {
										sortID:this.sortID,
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}else if(this.sortID == 4){
								this.$router.push({
									path: '/MeetWriteDian',
									query: {
										
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}else if(this.sortID == 5){
								this.$router.push({
									path: '/MeetWriteTie',
									query: {
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}else if(this.sortID == 2 || this.sortID == 7){
								this.$router.push({
									path: '/MeetWriteShaYan',
									query: {
										sortID:this.sortID,
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}
							else if(this.sortID == 8){
								this.$router.push({
									path: '/MeetWriteXue',
									query: {
										sortID:this.sortID,
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}
							else if(this.sortID == 6){
								this.$router.push({
									path: '/MeetWriteBao',
									query: {
										sortID:this.sortID,
										meetType:this.meetType,
										xyID:this.xyID,
										meetID:_d.data.list.department_meet_id
									}
								});
							}
							
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
				
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 528px;
		height: 274px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;
		img{
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}
		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		ul {
			width: 90%;
			margin: 0 auto;
			height: 145px;
			    overflow: auto;
			li {
				width: 100%;
				height: 32px;
				line-height: 32px;
				border-radius: 2px;

				margin-bottom: 10px;
				text-align: center;
				cursor: pointer;
			}

			.choose {
				background-color: #1890ff;
				color: #fff;
			}
		}

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
