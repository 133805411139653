<template>
	<div v-if="show" v-loading="loading">
		<Mask></Mask>
		<div class="box">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">会议问题及解答</div>
			<div class="que">
				<span>问题</span>
				<input type="text" v-model="que" placeholder="请填写">
			</div>
			<div class="ans">
				<span>解答</span>
				<el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="ans">
				</el-input>

			</div>
			<button @click="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "AddHospitalBox",
		data() {
			return {
				show: false,
				loading: false,
				que: '',
				ans: '',
				ind: '',

			}
		},
		components: {
			Mask
		},
		created() {
			// this.$eventBus.off('showAddQuestionBox')
			this.$eventBus.on('showAddQuestionBox', data => {
				//(data)
				this.show = data.data
				this.sortID = data.id
				this.ind = data.ind
			})
		},
		beforeDestroy() {
			this.$eventBus.off('showAddQuestionBox')
		},
		methods: {
			//关闭
			close() {
				this.show = false
				//this.$eventBus.emit('showQuestionBox',{
				//	show:true
				//})
			},
			//确定
			submit() {
				if (!this.que || !this.ans) {
					ElMessage({
						message: '请填写完整',
						type: 'warning',
					})
					return
				}
				this.show = false;
				
				this.$eventBus.emit('writeQue', {
					ind: this.ind,
					id: '',
					que: this.que,
					ans: this.ans
				})
				this.que = '';
				this.ans = '';
			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 828px;
		height: 463px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 300;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.que {
			//width:70%;
			text-align: left;
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				font-size: 14px;
				color: #000;
				margin: 0 30px 0 0;
			}

			input {
				border: 1px solid #d9d9d9;
				width: 312px;
				height: 35px;
				border-radius: 2px;
				padding: 0 5px;
			}
		}

		.ans {
			margin: 20px 0 0 0;
			//width:70%;
			text-align: left;
			display: flex;
			flex-direction: row;

			span {
				font-size: 14px;
				color: #000;
				margin: 0 30px 0 0;
			}

			.el-textarea {
				width: 325px;

			}
		}

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 40px 0 0 0;
			cursor: pointer;
		}
	}
</style>
