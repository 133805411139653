<template>
  <div class="about">
	<Titles></Titles>
    <Huis />
	<ModeBox></ModeBox>
  </div>
</template>

<script>
// @ is an alias to /src
import Titles from "@/components/titles.vue";
import Huis from "@/components/Huis.vue";
import ModeBox from "@/components/ModeBox.vue";


export default {
  name: "Hui",
  components: {
	Titles,
    Huis,
	ModeBox
  },
};
</script>
<style scoped lang="scss">
	.about{
		height: 100%;
	}
</style>