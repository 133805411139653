<template>
	<div class="home">
		<div class="sort">
			<p>会议</p>
		</div>
		<div class="list">
			<div class="one" v-for="item in info" @click="nav(item.id)">
				<div class="tops">
					<img :src='item.icon' />
					<span>{{item.name}}</span>
				</div>
				<p>进行{{item.name}}的相关操作</p>
				<p>{{company}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'

	export default {
		name: "HelloWorld",
		components: {

		},
		props: {

		},
		data() {
			return {
				info: '',
				company: '',
			}

		},
		watch: {

		},
		created() {
			// this.$eventBus.off('brotherEvent')
			//document.onselectstart=function(){returnfalse;}
			this.$eventBus.on('brotherEvent', res => {
				this.company = res;
			})
			this.getListInfo()
		},
		mounted() {

		},
		destroyed() {

		},
		methods: {
			//跳转到下级页面
			nav(_id) {
				// if(_id == 1|| _id == 2 || _id == 3 || _id == 4 || _id == 5 || _id == 7 || _id == 8){
				// if(_id == 1){
					this.$eventBus.emit('show1', {
						id: _id,
						data: true
					})
				// }
				

			},
			//获取列表
			getListInfo() {
				let obj = {
					//u_id:localStorage.getItem("u_id")
				}
				let a = qs.stringify(obj)


				axios.post(API_LIST.memory, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						

						if (_d.data.status == 200) {
							this.info = _d.data.list;

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			}
		}
	};
</script>

<style scoped lang="scss">
	.home{
		background-color: #fff;
		height: 100%;
	}
	.sort {
		width: 1050px;
		margin: 20px auto;
		font-weight: 800;
		border-bottom: 1px solid #eee;
		padding: 10px 0 10px 40px;

		p {
			font-size: 18px;
		}
	}

	.list {
		width: 1050px;
		margin: 20px auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.one {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 200px;
			height: 120px;
			padding: 25px;
			border: 1px solid #eee;

			.tops {
				display: flex;
				flex-direction: row;
				align-items: center;

				img {
					width: 30px;
					height: 30px;
					margin: 0 10px 0 0;
				}

				p:nth-child(2) {
					width: 200px;
					height: 50px;
					overflow: hidden;
					margin: 15px 0;
				}
			}
		}
	}
</style>
