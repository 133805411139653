<template>
  <div class="about">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <DownLius />
  </div>
</template>

<script>
// @ is an alias to /src
import DownLius from "@/components/DownLius.vue";


export default {
  name: "UpData",
  components: {
    DownLius
  },
};
</script>
<style lang="scss">
	.about{
		min-width: 1200px;
		height: 100%;
	}
</style>
