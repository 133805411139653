<template>
	<div v-if="show">
		<Mask></Mask>
		<div class="box" v-loading="loading">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">费用项目</div>
			<div class='add'>
				<p class='addKe' @click="addTheme">+新增费用项目</p>
			</div>
			<ul>
				<li @click="choose(index)" v-for="(item,index) in menuList" :class="{'choose':index===chooseNum}">
					{{item.name}}
				</li>
				<li v-if="menuList.length==0">暂无数据</li>
			</ul>
			<button @click="submit">确定</button>
		</div>
		<el-dialog title="新增费用项目" v-model="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="费用项目内容" :label-width="formLabelWidth">
					<el-input v-model="newThemeName" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="text-align:end!important;">
				<el-button @click="dialogFormVisible=false">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "ThemeBox",
		data() {
			return {
				show: false,
				menuList: '',
				chooseArr: [],
				ind: '', //费用项目索引
				chooseNum: '', //默认选中
				page: 1,
				type: '', //类型id
				dialogFormVisible: false,
				newThemeName: '', //新科室名称
				formLabelWidth: '120px',
				loading:false,
			}
		},
		components: {
			Mask
		},
		props: ['getBudgetName'],
		created() {
			// this.$eventBus.off('showBudgetBox')
			this.$eventBus.on('showBudgetBox', data => {
				this.type = data.type;
				this.show = data.show;
				this.ind = data.ind;
				this.getListInfo()
			})

		},
		methods: {
			//选中
			choose(_ind) {
				this.chooseNum = _ind
				this.chooseArr = [this.menuList[_ind]]
			},
			//关闭
			close() {
				this.show = false
			},
			//请求协议列表
			getListInfo() {
				this.loading = true
				let obj = {
					page: this.page
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.expense_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						this.loading = false
						if (_d.data.status == 200) {
							this.menuList = _d.data.list;
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//确定
			submit() {
				if (this.chooseArr.length > 0) {
					this.sendThemeName()
				} else {
					ElMessage({
						message: '请选择会议主题',
						type: 'warning',
					})
				}
			},
			//把值传给父组件
			sendThemeName() {
				this.show = false
				this.getBudgetName({
					name: this.chooseArr[0],
					ind: this.ind,
					type: this.type
				})
				this.chooseNum = ''
				this.chooseArr = []
			},
			//新建名称
			addTheme() {
				this.dialogFormVisible = true;
			},
			//确定添加名称
			confirm() {
				if (this.newThemeName) {
					this.addThemeName(this.newThemeName)
					this.dialogFormVisible = false
				} else {
					ElMessage({
						message: '请填写新费用项目',
						type: 'warning',
					})
				}
			},
			//添加名称接口
			addThemeName() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					name: this.newThemeName
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.add_expense, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							this.menuList.unshift(_d.data.list)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},

		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 628px;
		height: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.add {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			width: 100%;
			padding: 0 30px;
			box-sizing: border-box;
			margin: 0 0 15px 0;
		
	.addKe {
				width: 124px;
				height: 28px;
				background: #e0f1ff;
				border-radius: 3px;
				color: #1890FF;
				text-align: center;
				line-height: 28px;
				cursor: pointer;
			}
		}


		ul {
			width: 90%;
			margin: 0 auto;
			height: 310px;
			overflow: auto;
		
	li {
				width: 100%;
				height: 32px;
				line-height: 32px;
				border-radius: 2px;

				margin-bottom: 10px;
				text-align: center;
				cursor: pointer;
			}

			.choose {
				background-color: #1890ff;
				color: #fff;
			}
		}
	
	.dialog-footer {
			text-align: end !important;
		}

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
