<template>
	<div class="box">
		<ul>
			<li v-for="(item,index) in list" @click="change(item.id)">
				<div class="left">
					<div :class="[index==0&&status[0].act_plan==1?'div1':index==1&&status[0].act_sum==1?'div1':index==2&&status[0].act_file==1?'div1':'div2']">{{index+1}}</div>
					<span>{{item.name}}</span>
				</div>
				<div :class="{'right':index==0&&status[0].act_plan==1,'right2':index==0&&status[0].act_plan!=1}"></div>
				<div :class="{'right':index==1&&status[0].act_plan==1,'right2':index==1&&status[0].act_plan!=1}"></div>
			</li>
		</ul>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";

	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "StatusBox",
		data() {
			return {
				list:[{
					id:1,
					name:'活动方案'
				},{
					id:2,
					name:'活动总结'
				},{
					id:3,
					name:'附件上传'
				},],
				meetID:'',
				status:[{}],
			}
		},
		components: {
			
		},
		props:['status_state'],
		mounted() {
			this.meetID = this.$route.query.meetID
			this.$eventBus.on('shua', data => {
				this.getListInfo()
			})
			this.getListInfo()

		},

		methods: {
			//获取状态
			getListInfo() {
				let obj = {
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)
			
				axios.post(API_LIST.act_state, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						
						if (_d.data.status == 200) {
							console.log('2222',_d.data)
							this.status = _d.data.list
							// this.$emit('change', 2);
							this.$emit('changeState', _d.data.list[0]);
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
			
						}
					})
					.catch(function(error) {
			
					});
			},
			// 切换模块
			change(_id){
				this.$emit('change', _id);
			},
			
		},
		beforeDestroy() {
			
		},
	}
</script>

<style scoped lang="scss">
	.box {
		display:flex;
		flex-direction: row;
		ul{
			display:flex;
			flex-direction: row;
			margin: 0 0 0 163px;
			li{
				// margin: 0 0 0 30px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				// align-items: center;
				justify-content: center;
				.left{
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					div{
						width: 66px;
						height: 66px;
						border-radius: 50%;
						background-color: #1890FF;
						text-align: center;
						line-height: 66px;
						font-size: 20px;
						color: #fff;
					}
					.div2{
						background-color: #1890FF;
						opacity: 0.5;
					}
				}
				.right{
					border: 1px solid #1890FF;
					width: 130px;
					height: 0px;
					margin: 33px 0 0 0;
				}
				.right2{
					border: 1px dashed #1890FF;
					width: 130px;
					height: 0px;
					margin: 33px 0 0 0;
				}
			}
			
		}
	}
</style>
