<template>
	<div v-if="show" v-loading="loading">
		<Mask></Mask>
		<div class="box">
			<img src="../../assets/close2.png" @click="close" alt="">
			<div class="title">添加{{this.meetSort == 1?'药店':'连锁'}}基础信息</div>
			<div class="name">
				<span>名称</span>
				<input type="text" v-model="hospitalName" placeholder="请填写">
			</div>
			<div class="area" v-if='words != "dian"'>
				<span>所在省市区</span>
				<el-cascader size="large" :options="pcaTextArr" v-model="selectedOptions">
				</el-cascader>
			</div>
			<div class="address" v-if='words != "dian"'>
				<span>详细地址</span>
				<input type="text" v-model="addressDetail" placeholder="请输入详细地址">
			</div>
			<button @click="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "AddHospitalBox",
		data() {
			return {
				show: false,
				loading: false,
				hospitalName: '', //医院名
				addressDetail: '', //详细地址
				pcaTextArr,
				selectedOptions: [],
				state: '',

			}
		},
		components: {
			Mask
		},
		created() {
			// this.$eventBus.off('show4')
			this.$eventBus.on('show4', data => {
				console.log(data)
				this.show = data.data
				this.state = data.state
				this.meetSort = data.meetSort
				this.words = data.words
			})
		},
		beforeDestroy() {
			this.$eventBus.off('show4')
		},
		methods: {
			//关闭
			close() {
				this.show = false
				this.$emit('show3', {
					show: true
				})
			},
			//确定
			submit() {
				const that = this;

				this.loading = true;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					address: that.addressDetail,
					name: that.hospitalName,
					province: this.selectedOptions[0],
					city: this.selectedOptions[1],

				}
				let a = qs.stringify(obj)
				let url = API_LIST.add_chain
				if(this.meetSort == 1){
					url = API_LIST.add_drugstore
				}
				axios.post(url, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						this.loading = false;
						if (_d.data.status == 200) {
							this.$message({
								message: _d.data.msg,
								type: 'success'
							});
							this.show = false;
							this.hospitalName = ''; //医院名
							this.addressDetail = ''; //详细地址
							this.pcaTextArr = this.pcaTextArr;
							this.selectedOptions = [];
							this.$emit('show3', {
								show: true
							})
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});


			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 528px;
		height: 363px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 300;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.name {
			width: 70%;
			text-align: left;
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				font-size: 14px;
				color: #000;
				margin: 0 30px 0 0;
			}
		}

		.area,
		.address {
			margin: 20px 0 0 0;
			width: 70%;
			text-align: left;
			display: flex;
			flex-direction: column;

			span {
				font-size: 14px;
				color: #000;
				margin: 0 0 10px 0;
			}

			input {
				border: 1px solid #d9d9d9;
				border-radius: 2px;
				height: 32px;
				line-height: 32px;
				padding: 0 0 0 15px;
			}
		}

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 40px 0 0 0;
			cursor: pointer;
		}
	}
</style>
