<template>
	<div class="home">
		<XyListBox v-if='showXyListBox' @close="closeParent($event)" :meetType='meetType' :sortID='sortID'></XyListBox>
		<div class="center">
			<div class="addKe" @click="addKeBtn">新建学术交流会</div>
			<div class="top">
				<ul>
					<li v-for='item in menuList'>{{item}}</li>
				</ul>
			</div>
			<div class="bottom">
				<ul v-for='(item,index) in infoList'>
					<li>{{item.id}}</li>
					<!-- <li>{{item.type_name}}</li> -->
					<li>{{item.product_name.toString()}}</li>
					<li>{{item.company_name}}</li>
					<!-- <li>{{item.depart_name.toString()}}</li> -->
					<li>{{item.server_time}}</li>
					<li>{{item.teacher}}</li>
					<li>{{item.state_name}}</li>
					<li class="li">
						<span v-if="item.state!=1" @click="look(item.id,item.agreement_id)">上传 |</span>
						<span @click="look(item.id,item.agreement_id)">查看</span>
						<span v-if="item.state!=1" @click="del(item.id)">| 删除</span>
					</li>
					<li v-if="item.state==4" @click="lookReason(item.fail_text)">查看驳回理由</li>
				</ul>
			</div>
		</div>
		<div class="fen">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page.sync="currentPage" :page-size="10" :small="small" layout="prev,pager,next,jumper"
				:total="totalNum">
			</el-pagination>
			<!--<el-paginationv-model:current-page="currentPage"v-model:page-size="pageSize":small="small"
				:disabled="disabled":background="background"layout="prev,pager,next,jumper":total="totalNum"
				@size-change="handleSizeChange"@current-change="handleCurrentChange"/>-->
		</div>

	</div>
</template>

<script>
	//引入axios
	import axios from 'axios';
	import XyListBox from "@/components/XyListBox.vue";
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "listHuis",
		components: {
			XyListBox
		},
		props: {

		},
		data() {
			return {
				menuList: ['ID','目标产品','药店名称', '会议时间', '主讲者', '会议状态', '操作'],
				infoList: '',
				page: 1,
				currentPage: 1, //当前页数
				pageSize: 10,
				small: false,
				background: false,
				disable: false,
				showXyListBox:false,
				meetType:'',
				sortID:'',
				
			}

		},
		watch: {

		},
		created() {

			this.meetType = this.$route.query.type
			console.log(111,this.meetType)
			this.sortID = this.$route.query.id
			this.getListInfo()
		},
		mounted() {

		},
		methods: {
			//跳转到查看科室会页
			look(_id, _xyID) {
				this.$router.push({
					path: '/MeetWriteXue',
					query: {
						sortID:this.sortID,
						xyID: _xyID,
						meetID: _id,
						meetType: this.meetType
					}
				});
			},
			//删除科室会
			del(_id) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: _id
				}
				let a = qs.stringify(obj)


				axios.post(API_LIST.del_department, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {


						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
							this.getListInfo()
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},
			//获取列表
			getListInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					memory_id:this.sortID,
					type: this.meetType,
					page: this.page
				}
				let a = qs.stringify(obj)


				axios.post(API_LIST.meet1_list_5, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {


						if (_d.data.status == 200) {
							this.infoList = _d.data.list.list;
							this.totalNum = _d.data.list.total_nums;
							(this.infoList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//查看驳回理由
			lookReason(_reason) {
				this.$notify.error({
					title: '消息',
					position: 'top-left',
					message: _reason
				});
			},
			handleSizeChange(number) {
				this.currentPage = number;
				this.page = number;
				this.getListInfo()
			},
			handleCurrentChange(number) {
				this.currentPage = number;
				this.page = number;
				this.getListInfo()
			},
			//点击新建科室会
			addKeBtn() {
				this.showXyListBox = true;
				// this.$eventBus.emit('show2', {
				// 	data: true,
				// 	type: this.type,
				// 	sortID: this.sortID
				// })
			},
			// 
			closeParent(e){
				this.showXyListBox = e.show;
				console.log(e)
			}
		}
	};
</script>

<style scoped lang="scss">
	.home {
		margin: 20px 0 0 0;
		background-color: #fff;

		.center {
			width: 1250px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			padding: 20px 0;

			.addKe {
				cursor: pointer;
				width: 128px;
				height: 28px;
				background: #e0f1ff;
				border-radius: 3px;
				color: #0073FF;
				line-height: 28px;
				text-align: center;
				align-self: flex-end;
			}

			.top {
				margin: 20px 0;
			
				ul {
					display: flex;
					flex-direction: row;
					// justify-content: space-between;
					font-size: 14px;
					font-weight: 800;
			
					li:nth-child(1) {
						width: 100px;
						text-align: center;
					}
					
					li:nth-child(2) {
						width: 230px;
						text-align: center;
					}
					
					li:nth-child(3) {
						width: 160px;
						text-align: center;
					}
					
					li:nth-child(4) {
						width: 230px;
						text-align: center;
					}
					
					li:nth-child(5) {
						width: 150px;
						text-align: center;
					}
					
					li:nth-child(6) {
						width: 100px;
						text-align: center;
					}
					
					li:nth-child(7) {
						width: 170px;
						text-align: center;
					}
					li:nth-child(8) {
						width: 120px;
						text-align: center;
					}
					li:nth-child(9) {
						width: 120px;
						text-align: center;
					}
				}
			
			
			}
			
			.bottom {
				display: flex;
				flex-direction: column;
			
				ul {
					display: flex;
					flex-direction: row;
					// justify-content: space-between;
					align-items: center;
					font-size: 14px;
					padding: 10px 0;
			
					//font-weight:800;
					li:nth-child(1) {
						width: 100px;
						text-align: center;
					}
					
					li:nth-child(2) {
						width: 230px;
						text-align: center;
					}
					
					li:nth-child(3) {
						width: 160px;
						text-align: center;
					}
					
					li:nth-child(4) {
						width: 230px;
						text-align: center;
					}
					
					li:nth-child(5) {
						width: 150px;
						text-align: center;
					}
					
					li:nth-child(6) {
						width: 100px;
						text-align: center;
					}
					
					li:nth-child(7) {
						width: 170px;
						text-align: center;
					}
					li:nth-child(8) {
						width: 100px;
						text-align: center;
						color:#2a5caa;
						cursor: pointer;
					}
					li:nth-child(9) {
						width: 120px;
						text-align: center;
					}
			
					li:nth-child(10) {
						position: absolute;
						right: 40px;
						width: 110px;
						text-align: center;
						background-color: #1890FF;
						color: #fff;
						border-radius: 5px;
						cursor: pointer;
					}
			
					.li {
						span {
							color: #1890FF;
							margin: 0 3px 0 0;
							cursor: pointer;
						}
			
						span:nth-child(3) {
							color: #f00;
						}
					}
				}
			
			}

		}

		.fen {
			text-align: center;
			padding: 0 0 20px 0;
		}

	}
</style>
