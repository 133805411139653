<template>
	<div class="about" onselectstart="return false">
		<div class="mask" v-show="showMask"></div>
		<Titles></Titles>
		<!-- 医院弹出框 -->
		<!-- <HospitalBox></HospitalBox> -->
		<div class="box">
			<div class="titles">所属科目：<span>产品培训会</span></div>
		</div>
		<NoticeChan ref='notice' @closes='closes' @show="show"></NoticeChan>
		<SheetChan ref='sheet'></SheetChan>
		<ProblemChan ref='problem'></ProblemChan>
		<SummaryChan ref='summary'></SummaryChan>
		<PlanChan ref='plan'></PlanChan>
		<UpfileChan ref='upfile'></UpfileChan>
		<div class="sub" v-if="!showMask">
			<button class='btn' @click='save'>保存</button>
			<button class='btn2' @click='submit'>提交</button>
		</div>
		<div class="mask2" v-show="showMask2"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import NoticeChan from "@/components/Chan/NoticeChan.vue";
	import SheetChan from "@/components/Chan/SheetChan.vue";
	import ProblemChan from "@/components/Chan/ProblemChan.vue";
	import Titles from "@/components/titles.vue";
	import HospitalBox from "@/components/HospitalBox.vue";
	import SummaryChan from "@/components/Chan/SummaryChan.vue";
	import PlanChan from "@/components/Chan/PlanChan.vue";
	import UpfileChan from "@/components/Chan/UpfileChan.vue";
	import axios from 'axios';

	import {

		ref

	} from 'vue'

	// 引入端口配置文件

	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'

	export default {
		name: "Hui",
		data() {
			return {
				showMask: true,
				sortID: '',
			}
		},
		components: {
			Titles,
			NoticeChan,
			SheetChan,
			HospitalBox,
			ProblemChan,
			SummaryChan,
			PlanChan,
			UpfileChan
		},
		created() {
			this.meetID = this.$route.query.meetID
			this.sortID = this.$route.query.sortID
			this.getState()
		},
		methods: {
			// 获取科室会状态
			getState() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				let a = qs.stringify(obj)

				axios.post(API_LIST.de_state4, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(777, _d)

						if (_d.data.status == 200) {
							this.showMask = _d.data.list.state == 1 ? true : false
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//
			show(){
				this.showMask2 = true;
			},
			// 
			closes(){
				this.showMask2 = false;
			},
			// 保存
			save() {
				console.log('---', this.$refs.upfile)
				let depart_process = {}
				let expense_item_arr1 = {
					type: 1,
					result: [],
					total: this.$refs.sheet.budgetTotal,
					bese: this.$refs.sheet.numberParseChina(this.$refs.sheet.budgetTotal)
				}
				let expense_item_arr2 = {
					type: 2,
					result: [],
					total: this.$refs.sheet.settlementTotal,
					bese: this.$refs.sheet.numberParseChina(this.$refs.sheet.settlementTotal)
				}
				let meet_question = this.$refs.problem.queList
				// 效果及评价
				let meet_se = {
					type2: this.$refs.summary.radio == 1 ? 1 : this.$refs.summary.radio == 2 ? 2 : '',
					answer: [{
						title: this.$refs.summary.queList.msg1,
						content: '',
						words: 'msg1',
						id: 9999
					}]
				}

				// 跟进及计划
				let meet_se_ed = {
					answer: []
				}


				if (this.$refs.sheet.radio == 1) {
					try {
						this.$refs.sheet.process.forEach(item => {
							if (item.times.length == 0 || !item.spker || !item.event) {
								ElMessage({
									message: '会议流程请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
					depart_process.result = this.$refs.sheet.process
					depart_process.type = this.$refs.sheet.radio
					// this.$refs.sheet.
				} else if (this.$refs.sheet.radio == 2) {
					depart_process.type = this.$refs.sheet.radio
				}


				// this.$refs.sheet.budgetTableList.forEach(item => {
				// 	expense_item_arr1.result.push({
				// 		expense_item_name: item.name,
				// 		expense_item_id: item.expense_item_id,
				// 		unit: item.price,
				// 		num: item.number,
				// 		unit_cost: Number(item.price) * Number(item.number)
				// 	})
				// })
				// this.$refs.sheet.settlementTableList.forEach(item => {
				// 	expense_item_arr2.result.push({
				// 		expense_item_name: item.name,
				// 		expense_item_id: item.expense_item_id,
				// 		unit: item.price,
				// 		num: item.number,
				// 		unit_cost: Number(item.price) * Number(item.number)
				// 	})
				// })

				// 预算表判定
				try {
					this.$refs.sheet.budgetTableList.forEach(item => {
						if (item.name == '' || item.price == '' || item.number == '') {
							ElMessage({
								message: '预算表请填写完整',
								type: 'error',
							})
							throw new Error('End Loop')
						} else {
							expense_item_arr1.result.push({
								expense_item_name: item.name,
								expense_item_id: item.expense_item_id,
								unit: item.price,
								num: item.number,
								unit_cost: Number(item.price) * Number(item.number)
							})
						}

					})
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}

				// 结算表判定
				try {
					this.$refs.sheet.settlementTableList.forEach(item => {
						if (item.name == '' || item.price == '' || item.number == '') {
							ElMessage({
								message: '结算表请填写完整',
								type: 'error',
							})
							throw new Error('End Loop')
						} else {
							expense_item_arr2.result.push({
								expense_item_name: item.name,
								expense_item_id: item.expense_item_id,
								unit: item.price,
								num: item.number,
								unit_cost: Number(item.price) * Number(item.number)
							})
						}

					})
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}

				// 会议中遇到的问题判定
				if (meet_question.length == 1 && !meet_question[0].title && !meet_question[0].content) {
					meet_question = []
				} else {
					try {
						meet_question.forEach(item => {
							if (item.title == '' || item.content == '') {
								ElMessage({
									message: '会议中遇到问题互动及解答请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}

						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
				}





				// 
				if (this.$refs.summary.radio == 1) {
					if (this.$refs.summary.chooseApprove.length == 0) {
						ElMessage({
							message: '请选择认可度',
							type: 'error',
						})
						return
					} else {
						meet_se.answer.push({
							title: this.$refs.summary.queList.msg2.attend_worker + '对' + this.$refs.summary.queList
								.msg2.product_name + '产品的认可度',
							content: this.$refs.summary.chooseApprove,
							words: 'msg2',
							id: 9999,
						})
					}

					try {
						this.$refs.summary.chooseArr.forEach((item, index) => {
							if (item != 999) {

								this.$refs.summary.ansList[item].ansChild.forEach((itemChild, indexChild) => {
									if (item.ans == '') {
										ElMessage({
											message: '选择的问题请把选项选择后再保存',
											type: 'error',
										})
										throw new Error('End Loop')
									} else {
										meet_se.answer.push({
											title: this.$refs.summary.queList.data[index][
												indexChild
											].question,
											content: this.$refs.summary.ansList[index].ansChild[
												indexChild].ans,
											words: 'data1',
											id: this.$refs.summary.queList.data[index][indexChild]
												.id
										})
									}
								})

							}
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}

					if (this.$refs.summary.addSummaryList.length > 0) {
						try {
							this.$refs.summary.addSummaryList.forEach(item => {
								if (!item.title) {
									ElMessage({
										message: '新增的会后总结会议效果及评价请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								meet_se.answer.push({
									title: item.title,
									content: '',
									words: 'data2',
									id: item.id
								})
							})
						} catch {
							if (e.message === 'End Loop') throw e
						}


					}

				} else if (this.$refs.summary.radio == 2 && this.$refs.summary.fileList.length == 0) {
					ElMessage({
						message: '会后总结会议效果及评价请上传文件',
						type: 'error',
					})
					return
				}


				try {
					this.$refs.plan.chooseArr.forEach((item, index) => {
						let s2 = ''
						if (item != 999) {
							this.$refs.plan.ansList[index].ansChild.forEach((itemChild, indexChild) => {

								if (this.$refs.plan.ansList[index].ansChild[indexChild].ans.length == 0 &&
									this.$refs.plan.queList.data[index][
										indexChild
									].result.length > 0) {
									ElMessage({
										message: '会后跟进及计划请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								meet_se_ed.answer.push({
									title: this.$refs.plan.queList.data[index][
										indexChild
									].question,
									content: this.$refs.plan.ansList[index].ansChild[indexChild]
										.ans,
									words: 'data1',
									id: this.$refs.plan.queList.data[index][indexChild]
										.id
								})
								if (this.$refs.plan.ansList[index].ansChild[
										indexChild].ans) {
									s2 = s2 + this.$refs.plan.queList.data[index][
										indexChild
									].question + this.$refs.plan.ansList[index].ansChild[
										indexChild].ans
								} else {
									s2 = s2 + this.$refs.plan.queList.data[index][
										indexChild
									].question
								}

							})

						}
						if (item != 999) {
							meet_se_ed.answer2.push(s2)
						}

						console.log(33, meet_se_ed)



					})
					console.log(2222, meet_se_ed)
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}
				if (this.$refs.plan.addSummaryList.length > 0) {
					try {
						this.$refs.plan.addSummaryList.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							meet_se_ed.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}


				}

				// this.$refs.summary.
				// 
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					submit_type: 1,
					p_type: 1,
					depart_process, // 会议流程
					expense_item_arr1, // 预算表
					expense_item_arr2, // 结算表
					meet_question, // 会议中遇到的问题及解答
					meet_se, // 会后总结效果及评价
					meet_se_ed, // 会后跟进效果
				}

				let a = qs.stringify(obj)

				axios.post(API_LIST.save_data6, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						// console.log(11, _d)

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});

			},
			// 提交
			submit() {
				console.log('---', this.$refs.upfile)
				let depart_process = {}
				let expense_item_arr1 = {
					type: 1,
					result: [],
					total: this.$refs.sheet.budgetTotal,
					bese: this.$refs.sheet.numberParseChina(this.$refs.sheet.budgetTotal)
				}
				let expense_item_arr2 = {
					type: 2,
					result: [],
					total: this.$refs.sheet.settlementTotal,
					bese: this.$refs.sheet.numberParseChina(this.$refs.sheet.settlementTotal)
				}
				let meet_question = this.$refs.problem.queList
				// 效果及评价
				let meet_se = {
					type2: this.$refs.summary.radio == 1 ? 1 : this.$refs.summary.radio == 2 ? 2 : '',
					answer: [{
						title: this.$refs.summary.queList.msg1,
						content: '',
						words: 'msg1',
						id: 9999
					}],
					answer2: []
				}

				// 跟进及计划
				let meet_se_ed = {
					answer: [],
					answer2: []
				}
				if(this.$refs.sheet.memory.fp_on == 1 && this.$refs.sheet.radio == ''){
					ElMessage({
						message: '会议流程请填写',
						type: 'error',
					})
					return
				}


				if (this.$refs.sheet.radio == 1) {
					try {
						this.$refs.sheet.process.forEach(item => {
							if (item.times.length == 0 || !item.spker || !item.event) {
								ElMessage({
									message: '会议流程请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
					depart_process.result = this.$refs.sheet.process
					depart_process.type = this.$refs.sheet.radio
					// this.$refs.sheet.
				} else if (this.$refs.sheet.radio == 2) {
					depart_process.type = this.$refs.sheet.radio
				}

				if (this.$refs.sheet.budgetTableList.length == 0 && this.$refs.sheet.memory.cost_on == 1) {
					ElMessage({
						message: '请填写预算表',
						type: 'error',
					})
					return
				}
				if (this.$refs.sheet.settlementTableList.length == 0 && this.$refs.sheet.memory.cost_on == 1) {
					ElMessage({
						message: '请填写结算表',
						type: 'error',
					})
					return
				}
				// 预算表判定
				try {
					this.$refs.sheet.budgetTableList.forEach(item => {
						if (item.name == '' || item.price == '' || item.number == '') {
							ElMessage({
								message: '预算表请填写完整',
								type: 'error',
							})
							throw new Error('End Loop')
						} else {
							expense_item_arr1.result.push({
								expense_item_name: item.name,
								expense_item_id: item.expense_item_id,
								unit: item.price,
								num: item.number,
								unit_cost: Number(item.price) * Number(item.number)
							})
						}

					})
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}

				// 结算表判定
				try {
					this.$refs.sheet.settlementTableList.forEach(item => {
						if (item.name == '' || item.price == '' || item.number == '') {
							ElMessage({
								message: '结算表请填写完整',
								type: 'error',
							})
							throw new Error('End Loop')
						} else {
							expense_item_arr2.result.push({
								expense_item_name: item.name,
								expense_item_id: item.expense_item_id,
								unit: item.price,
								num: item.number,
								unit_cost: Number(item.price) * Number(item.number)
							})
						}

					})
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}
				// 会议中遇到的问题判定
				if (this.$refs.problem.memory.pro_on == 1 && meet_question.length == 0) {
					ElMessage({
						message: '请填写会议中遇到问题互动及解答',
						type: 'error',
					})
					return
				}

				try {
					meet_question.forEach(item => {
						if (this.$refs.problem.memory.pro_on == 1) {
							if (item.title == '' || item.content == '') {
								ElMessage({
									message: '会议中遇到问题互动及解答请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
						}else{
							if(meet_question.length == 1){
								if(item.title == '' && item.content == ''){
									meet_question = []
								}else if(item.title == '' || item.content == ''){
									ElMessage({
										message: '会议中遇到问题互动及解答请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
							}
						}

					})
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}



				// 
				if (this.$refs.summary.radio == 1) {
					if (this.$refs.summary.chooseApprove.length == 0) {
						ElMessage({
							message: '请选择认可度',
							type: 'error',
						})
						return
					} else {
						meet_se.answer.push({
							title: this.$refs.summary.queList.msg2.attend_worker + '对' + this.$refs.summary.queList
								.msg2.product_name + '产品的认可度',
							content: this.$refs.summary.chooseApprove,
							words: 'msg2',
							id: 9999,
						})
					}

					try {

						this.$refs.summary.chooseArr.forEach((item, index) => {
							let s = ''
							if (item != 999) {
								this.$refs.summary.ansList[item].ansChild.forEach((itemChild, indexChild) => {
									if (itemChild.ans.length == 0 && this.$refs.summary.queList.data[index]
										[
											indexChild
										].result.length > 0) {
										ElMessage({
											message: '选择的问题请把选项选择后再保存',
											type: 'error',
										})
										throw new Error('End Loop')
									} else {
										meet_se.answer.push({
											title: this.$refs.summary.queList.data[index][
												indexChild
											].question,
											content: this.$refs.summary.ansList[index].ansChild[
												indexChild].ans,
											words: 'data1',
											id: this.$refs.summary.queList.data[index][indexChild]
												.id
										})
										s = s + this.$refs.summary.queList.data[index][
											indexChild
										].question + this.$refs.summary.ansList[index].ansChild[
											indexChild].ans
										// console.log(a)
										// datas = datas+this.$refs.summary.queList.data[index][
										// 	indexChild
										// ].question+this.$refs.summary.ansList[index].ansChild[
										// 		indexChild].ans


									}

								})

							}

							if (item != 999) {
								console.log(s)
								meet_se.answer2.push(s)
							}


						})

					} catch (e) {
						if (e.message === 'End Loop') throw e
					}




					if (this.$refs.summary.addSummaryList.length > 0) {
						try {
							this.$refs.summary.addSummaryList.forEach(item => {
								if (!item.title) {
									ElMessage({
										message: '新增的会后总结会议效果及评价请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								meet_se.answer.push({
									title: item.title,
									content: '',
									words: 'data2',
									id: item.id
								})

							})
						} catch {
							if (e.message === 'End Loop') throw e
						}


					}

					if (this.$refs.summary.addSummaryList.length != 0 || this.$refs.summary.chooseArr.some((val) => {
							return val != 999
						})) {

					} else {
						ElMessage({
							message: '会后总结会议效果及评价自选或者添加项至少填写一项',
							type: 'error',
						})
						return
					}

				} else if (this.$refs.summary.radio == 2 && this.$refs.summary.fileList.length == 0) {
					ElMessage({
						message: '会后总结会议效果及评价请上传文件',
						type: 'error',
					})
					return
				} else if (this.$refs.summary.radio == 0) {
					ElMessage({
						message: '会后总结会议效果及评价请选择',
						type: 'error',
					})
					return
				}



				if (this.$refs.plan.addSummaryList.length != 0 || this.$refs.plan.chooseArr.some((val) => {
						return val != 999
					})) {

				} else {
					ElMessage({
						message: '会后跟进及计划自选或者添加项至少填写一项',
						type: 'error',
					})
					return
				}

				console.log(this.$refs.plan.chooseArr)

				try {
					this.$refs.plan.chooseArr.forEach((item, index) => {
						let s2 = ''
						if (item != 999) {
							this.$refs.plan.ansList[index].ansChild.forEach((itemChild, indexChild) => {
								console.log('----------------------')
								console.log(this.$refs.plan.ansList)
								console.log(this.$refs.plan.ansList[index].ansChild[indexChild].ans)
								console.log(this.$refs.plan.ansList[index].ansChild[indexChild].ans.length)
								console.log(this.$refs.plan.queList.data[index][
									indexChild
								].result.length)
								if (this.$refs.plan.ansList[index].ansChild[indexChild].ans.length == 0 &&
									this.$refs.plan.queList.data[index][
										indexChild
									].result.length > 0) {
									ElMessage({
										message: '会后跟进及计划请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								meet_se_ed.answer.push({
									title: this.$refs.plan.queList.data[index][
										indexChild
									].question,
									content: this.$refs.plan.ansList[index].ansChild[indexChild]
										.ans,
									words: 'data1',
									id: this.$refs.plan.queList.data[index][indexChild]
										.id
								})
								if (this.$refs.plan.ansList[index].ansChild[
										indexChild].ans) {
									s2 = s2 + this.$refs.plan.queList.data[index][
										indexChild
									].question + this.$refs.plan.ansList[index].ansChild[
										indexChild].ans
								} else {
									s2 = s2 + this.$refs.plan.queList.data[index][
										indexChild
									].question
								}

							})

						}
						if (item != 999) {
							meet_se_ed.answer2.push(s2)
						}

						console.log(33, meet_se_ed)



					})
					console.log(2222, meet_se_ed)
				} catch (e) {
					if (e.message === 'End Loop') throw e
				}
				if (this.$refs.plan.addSummaryList.length > 0) {
					try {
						this.$refs.plan.addSummaryList.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							meet_se_ed.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
				}



				// return

				// 判断文件
				if (this.$refs.upfile.meetType == 1 || this.$refs.upfile.meetType == 3) {
					if (this.$refs.upfile.trainingImg.length < this.$refs.upfile.m_img_num) {
						ElMessage({
							message: '网络培训图片至少上传2张',
							type: 'error',
						})
						return
					}
				}
				if (this.$refs.upfile.meetType == 2 || this.$refs.upfile.meetType == 3) {
					if (this.$refs.upfile.signImg.length < 1) {
						ElMessage({
							message: '签到图片至少上传1张',
							type: 'error',
						})
						return
					}
					if (this.$refs.upfile.meetImg.length < this.$refs.upfile.m_img_num) {
						ElMessage({
							message: '会议图片至少上传2张',
							type: 'error',
						})
						return
					}
				}
				if (this.$refs.upfile.ppt.length == 0 && this.$refs.upfile.memory.ppt_on == 1) {
					ElMessage({
						message: 'ppt至少上传1份',
						type: 'error',
					})
					return
				}




				// this.$refs.summary.
				// 
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					submit_type: 2,
					p_type: 1,
					depart_process, // 会议流程
					expense_item_arr1, // 预算表
					expense_item_arr2, // 结算表
					meet_question, // 会议中遇到的问题及解答
					meet_se, // 会后总结效果及评价
					meet_se_ed, // 会后跟进效果
				}

				let a = qs.stringify(obj)

				axios.post(API_LIST.save_data4, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						// console.log(11, _d)

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.$router.go(-1)

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		}
	};
</script>
<style lang="scss" scoped>
	.about {
		height: 100%;

	}

	.mask,.mask2 {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 100;
		background-color: #fff;
		opacity: 0.3;
	}
	.mask2{
		background-color: #000;
	}

	.box {
		margin: 15px 0;
		padding: 25px 0;
		background-color: #fff;

		.titles {
			width: 1050px;
			margin: 0 auto;
			font-size: 20px;
			font-weight: 800;
		}
	}

	.sub {
		padding: 40px 0;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;
		position: relative;
	}

	.btn,
	.btn2 {
		width: 65px;
		height: 32px;
		background: #1890ff;
		text-align: center;
		line-height: 32px;
		margin: 0 0 0 55px;
		color: #fff;
		border-radius: 10px;
		// margin: 0 auto;
	}

	.btn {
		background-color: #fff;
		border: 1px solid #1890ff;
		color: #1890ff;
	}
</style>
