<template>
  <div class="about">
	<Titles></Titles>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <Promotions />
  </div>
</template>

<script>
// @ is an alias to /src
import Promotions from "@/components/Promotion/Promotions.vue";
import Titles from "@/components/titles.vue";


export default {
  name: "UpData",
  components: {
    Promotions,
	Titles
  },
};
</script>
<style lang="scss">
	.about{
		min-width: 1200px;
		height: 100%;
	}
</style>
