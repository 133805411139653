<template>
	<div class="about" onselectstart="return false">
		<!-- <div class="mask" v-show="showMask"></div> -->
		<Titles></Titles>
		<!-- 模块框 -->
		<StatusBox @change='change' :status_state='status_state' :chu='chu' @changeState='changeState'></StatusBox>
		<!-- 活动方案 -->
		<Plan v-if='id == 1&&status_state.state' :status_state='status_state' @changeStatePlan='changeStatePlan'></Plan>
		<!-- 活动总结 -->
		<Summary v-if='id == 2&&status_state.state' :status_state='status_state' @changeStateSummary='changeStateSummary'></Summary>
		<!-- 附件上传 -->
		<Upload v-if='id == 3&&status_state.state' :status_state='status_state' @changeStateUpload='changeStateUpload'></Upload>
	</div>
</template>

<script>
	// @ is an alias to /src

	import Titles from "@/components/titles.vue";
	import StatusBox from "@/components/Promotion/StatusBox.vue";
	import Plan from "@/components/Promotion/Plan.vue";
	import Summary from "@/components/Promotion/Summary.vue";
	import Upload from "@/components/Promotion/Upload.vue";
	import axios from 'axios';

	import {

		ref

	} from 'vue'

	// 引入端口配置文件

	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'

	export default {
		name: "Hui",
		data() {
			return {
				showMask: true,
				id:1,
				status_state:'',
				chu:0,
			}
			
		},
		components: {
			Titles,
			StatusBox,
			Plan,
			Summary,
			Upload

		},
		created() {
			this.meetID = this.$route.query.meetID
			// this.getState()
		},
		methods: {
			change(_id) {
				console.log(_id)
				this.id = _id
			},
			// 
			changeState(data){
				console.log(data)
				this.status_state = data
			},
			//
			changeStatePlan(data){
				console.log('触发1')
				this.$eventBus.emit('shua')
			},
			//
			changeStateSummary(data){
				console.log('触发2')
				this.$eventBus.emit('shua')
			},
			//
			changeStateUpload(data){
				this.$eventBus.emit('shua')
			},
		}
	};
</script>
<style lang="scss" scoped>
	.about {
		height: 100%;

	}

	.mask {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 1000000;
		background-color: #fff;
		opacity: 0.3;
	}

	.box {
		margin: 15px 0;
		padding: 25px 0;
		background-color: #fff;

		.titles {
			width: 1050px;
			margin: 0 auto;
			font-size: 20px;
			font-weight: 800;
		}
	}

	.sub {
		padding: 40px 0;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;
		position: relative;
	}

	.btn,
	.btn2 {
		width: 65px;
		height: 32px;
		background: #1890ff;
		text-align: center;
		line-height: 32px;
		margin: 0 0 0 55px;
		color: #fff;
		border-radius: 10px;
		// margin: 0 auto;
	}

	.btn {
		background-color: #fff;
		border: 1px solid #1890ff;
		color: #1890ff;
	}
</style>
