<template>
	<div>
		<Mask></Mask>
		<div class="box" v-loading="loading">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">问答列表</div>
			<div class="tops">
				<div class="search">
					<span>会议中遇到的问题</span>
					<input v-model='searchVal' type="text" placeholder="请输入关键字">
					<button @click='search'>搜索</button>
				</div>
				<div class="addHospital" @click='addQuestion'>+新建会议问题</div>
			</div>
			<div class="que">
				<ul>
					<li @click="switchs(1)" :class="{'choose':1==chooseMenu}">系统问答</li>
					<li @click="switchs(2)" :class="{'choose':2==chooseMenu}">用户问答</li>
				</ul>
				<ul>
					<li @click="choose(index)" v-for="(item,index) in menuList"
						:class="{'choose':index==chooseNum,'detail':index!=detailCss}">
						<span>{{item.question}}</span>
						<span>{{item.answer}}</span>
						<!--<el-popoverplacement="top"title="标题"width="200"trigger="click"
							content="item.answer">
							<el-buttonslot="reference">{{'详情'}}</el-button>
						</el-popover>-->
						<span @click.stop="detail(index)">{{detailCss == index?'折叠':'展开'}}</span>
					</li>
					<li v-if='menuList.length==0' style="color:deepskyblue;">--暂无数据--</li>
				</ul>
			</div>

			<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
				<span>这是一段信息</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible=false">取消</el-button>
					<el-button type="primary" @click="dialogVisible=false">确定</el-button>
				</span>
			</el-dialog>
			<!--<divclass="fen">
				<el-pagination@size-change="handleSizeChange"@current-change="handleCurrentChange"
					:current-page.sync="currentPage":page-size="10":small="small"layout="prev,pager,next,jumper"
					:total="totalNum">
				</el-pagination>
			</div>-->
			<button class="submit" @click="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";

	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "QuestionBox",
		data() {
			return {
				menuList: '',
				hospitalID: '',
				Q_A: {
					id: '',
					que: "",
					ans: ""
				},
				searchVal: '', // 搜索词
				chooseNum: null, //默认选中
				chooseMenu: 1, //
				page: 1,
				type: '', //类型id
				totalNum: '',
				currentPage: 1,
				loading: false,
				detailCss: null,
				showMask: true, //遮罩层
			}
		},
		components: {
			Mask,
		},
		props:['meetID','ind','state'],
		created() {
			// this.$eventBus.off('showQuestionBox')
			// this.$eventBus.on('showQuestionBox', data => {
			// 	console.log(111,data)
			// 	// this.meetID = data.meetID;
			// 	// this.ind = data.ind
			// 	this.getListInfo()
			// })
			this.getListInfo()
			//遮罩层
			
			//this.getListInfo()
		},
		beforeDestroy() {
			console.log(12345)
			this.$eventBus.off('showQuestionBox')
		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message: '请先完成线上会议通知内容',
					type: 'warning',
				})
			},
			//选中
			choose(_ind) {
				this.Q_A = {
					id: this.menuList[_ind].id,
					que: this.menuList[_ind].question,
					ans: this.menuList[_ind].answer,
				}
				this.chooseNum = _ind
			},
			//关闭
			close() {
				this.chooseNum = null
				this.chooseMenu = 1
				this.$emit('close', {
					show:false,
					state:1,
				});
			},
			//请求协议列表
			getListInfo() {
				this.loading = true;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					keywords: this.searchVal
				}
				let a = qs.stringify(obj)
				let api = ''
				if(this.state == 1){
					api = API_LIST.memory_qa
				}else if(this.state == 3){
					api = API_LIST.memory_qa_6
				}else if(this.state == 4){
					api = API_LIST.memory_qa_3
				}else if(this.state == 5){
					api = API_LIST.memory_qa_2
				}else if(this.state == 2 || this.state == 7){
					api = API_LIST.memory_qa_4
				}else if(this.state == 8){
					api = API_LIST.memory_qa_5
				}else if(this.state == 6){
					api = API_LIST.memory_qa_7
				}
				
				
				axios.post(api, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						this.loading = false;
						if (_d.data.status == 200) {
							this.menuList = _d.data.list;
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//搜索
			search() {
				this.chooseNum = null
				this.page = 1;
				this.currentPage = 1;
				if (this.chooseMenu == 1) {
					this.getListInfo()
				} else {
					this.getQuestion()
				}

			},
			//切换分类
			switchs(_status) {
				this.chooseNum = null
				this.chooseMenu = _status;
				if (_status == 1) {
					this.getListInfo()
				} else {
					this.getQuestion()
				}
			},
			//获取别人的问答
			getQuestion() {
				this.loading = true;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					keywords: this.searchVal,
					type: 1
				}
				let a = qs.stringify(obj)
				let api = ''
				if(this.state == 1){
					api = API_LIST.memery_ed_data
				}else if(this.state == 5){
					api = API_LIST.memery_ed_data_2
				}else if(this.state == 4){
					api = API_LIST.memery_ed_data_3
				}else if(this.state == 2 || this.state == 7){
					api = API_LIST.memery_ed_data_4
				}else if(this.state == 8){
					api = API_LIST.memery_ed_data_5
				}else if(this.state == 3){
					api = API_LIST.memery_ed_data_6
				}else if(this.state == 6){
					api = API_LIST.memery_ed_data_7
				}
				console.log(this.state)
				console.log(api)

				axios.post(api, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						this.loading = false;
						console.log(_d)
						if (_d.data.status == 200) {
							this.menuList = _d.data.list;
							(this.menuList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//切页
			handleSizeChange(number) {

				this.page = number
				this.chooseNum = null
				this.getListInfo()
			},
			//跳页
			handleCurrentChange(number) {

				this.page = number
				this.chooseNum = null
				this.getListInfo()
			},
			//新建问题
			addQuestion() {
				this.$emit('close', {
					show:false,
					state:1,
				});
				this.$eventBus.emit('showAddQuestionBox', {
					data: true,
					ind: this.ind
				})
			},
			//确定
			submit() {

				if (this.Q_A.id) {
					this.chooseHospital()
				} else {
					ElMessage({
						message: '请选择问题',
						type: 'warning',
					})
				}
			},
			//确定医院
			chooseHospital() {
				
				this.$emit('close',{
					show:false,
					state:2,
					ind: this.ind,
					id: this.Q_A.id,
					que: this.Q_A.que,
					ans: this.Q_A.ans
				});
				// this.$eventBus.emit('Q_AChoose', {
				// 	ind: this.ind,
				// 	id: this.Q_A.id,
				// 	que: this.Q_A.que,
				// 	ans: this.Q_A.ans
				// })
				this.chooseMenu = 1
				this.chooseNum = null
			},
			//查看详情
			detail(_ind) {
				if (_ind == this.detailCss) {
					this.detailCss = null
				} else {
					this.detailCss = _ind
				}

			}

		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 828px;
		height: 674px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;
		}

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.tops {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 750px;
			margin: 0 0 20px 0;

			.search {
				display: flex;
				flex-direction: row;
				align-items: center;

				span {
					font-size: 14px;
				}

				input {
					width: 272px;
					height: 32px;
					background: #ffffff;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					margin: 0 15px;
					padding: 0005px;
				}

				button {
					width: 65px;
					height: 32px;
					background: #1890ff;
					border-radius: 2px;
					color: #fff;
				}
			}

			.addHospital {
				width: 117px;
				height: 28px;
				background: #e0f1ff;
				border-radius: 3px;
				text-align: center;
				line-height: 28px;
				font-size: 14px;
				color: #1890FF;
				cursor: pointer;
			}
		}

		.que {
			width: 90%;
			display: flex;
			flex-direction: row;

			ul:nth-child(1) {
				display: flex;
				flex-direction: column;
				margin: 5px 20px 5px 0;

				/**/
				li {
					margin-bottom: 15px;
					font-size: 16px;
					width: 100px;
					height: 45px;
					text-align: center;
					line-height: 45px;
					cursor: pointer;
				}

				.choose {
					background-color: #1890ff;
					color: #fff;
					border-radius: 5px;
				}
			}

			ul:nth-child(2) {
				width: 90%;
				margin: 0 auto;
				height: 445px;
				//display:flex;
				//flex-direction:column;
				overflow: auto;

				li {
					width: 100%;
					//height:32px;
					border-radius: 2px;
					line-height: 32px;
					margin-bottom: 10px;
					//text-align:center;
					cursor: pointer;
					padding: 0 0 0 20px;
					box-sizing: border-box;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: flex-start;


					span:nth-child(1) {
						width: 198px;
					}

					span:nth-child(2) {
						width: 298px;
						//margin:040px00;
					}

					span:nth-child(3) {
						//width:298px;
						margin: 2px 10px 0 0;
						border: 1px solid #fff;
						height: 25px;
						width: 35px;
						line-height: 25px;
						text-align: center;
						border-radius: 5px;

					}

				}

				.detail {
					height: 32px;
					overflow: hidden;
				}

				.choose {
					background-color: #1890ff;
					color: #fff;
				}
			}
		}


		.submit {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
