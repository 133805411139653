<template>
	<div class="about">
		<Titles></Titles>
		<ListHuis v-if='id==1' />
		<!-- 沙龙&研讨 -->
		<ListHuiShaYan v-if='id==2 || id==7' />
		<!-- 产品培训会 -->
		<ListHuiChan v-if='id==3' />
		<!-- 店员 -->
		<ListHuiClerk v-if='id==4' />
		<!-- 贴柜 -->
		<ListHuiStick v-if='id==5' />
		<!-- 爆破 -->
		<ListHuiBao v-if='id==6' />
		<!-- 学术 -->
		<ListHuiXue v-if='id==8' />
	</div>
</template>

<script>
	// @ is an alias to /src
	import ListHuis from "@/components/ListHuis.vue";
	import ListHuiStick from "@/components/Stick/ListHuis.vue";
	import ListHuiClerk from "@/components/Clerk/ListHuis.vue";
	import ListHuiShaYan from "@/components/ShaYan/ListHuis.vue";
	import ListHuiXue from "@/components/Science/ListHuis.vue";
	import ListHuiChan from "@/components/Chan/ListHuis.vue";
	import ListHuiBao from "@/components/Bao/ListHuis.vue";
	import Titles from "@/components/titles.vue";

	export default {
		name: "Hui",
		data() {
			return {
				id: '',
			}
		},
		components: {
			Titles,
			ListHuis,
			ListHuiStick,
			ListHuiShaYan,
			ListHuiXue,
			ListHuiClerk,
			ListHuiChan,
			ListHuiBao
		},
		created() {
			this.id = this.$route.query.id
		},
		
		methods: {

		}
	};
</script>
<style lang="scss">
	.about {
		height: 100%;
	}
</style>
