<template>
	<div class="hello">
		<div class="head">
			<div class="logo">
				<img src='../assets/logo.png' />
				<span>{{info.user_name}}</span>
			</div>
			<div class="close" @click="quit">
				<img src="../assets/close.png" />
				<span>退出</span>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	
	
	export default {
		name: "HelloWorld",
		props: {
	
		},
		data() {
			return {
				info:'',
			}
	
		},
		watch: {
			
		},
		created() {
			this.getInfo()
		},
		mounted() {
			if (!localStorage.getItem("u_id")) {
				this.$router.push({
					path: '/',
					query: {
			
					}
				});
			} else {
				// this.department_meet_id = this.$route.query.department_meet_id
				// this.state = this.$route.query.state
			
				// console.log(this.$route.query)
				this.getInfo()
			}
		},
		methods: {
			// 返回
			back() {
				this.$router.go(-1)
			},
			getInfo(){
				let obj = {
					u_id: localStorage.getItem("u_id")
				}
				let a = qs.stringify(obj)
				console.log(a)
				
				axios.post(API_LIST.pc_fun, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						// console.log(_d)
				
						if (_d.data.status == 200) {
							this.info = _d.data.list;
							this.$eventBus.emit('brotherEvent', this.info.company_name)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
				
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 
			quit(){
				window.sessionStorage.clear();
				this.$router.push({
					path: '/',
					query: {
						
					}
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	.head {
		background-color: #fff;
		height: 48px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 1px solid #f0f2f5;
	
		.logo,
		.close {
			img {
				height: 26px;
			}
	
			span {
				margin: 0 25px 0 10px;
				font-size: 14px;
			}
		}
	
		.close {
			span {
				color: #0073FF;
			}
		}
	
		.close:hover {
			cursor: pointer;
		}
	}
</style>