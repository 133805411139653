<template>
	<div class="sheet">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<!--<AddHospitalBox></AddHospitalBox>-->
		<RadioBox v-show="show1" ></RadioBox>
		<CheckBox v-show="show2" ></CheckBox>
		<OtherBox v-show="show3" ></OtherBox>
		<!--<ThemeBox:getThemeName="getThemeName"></ThemeBox>
		<TargetBox:getTargetName="getTargetName"></TargetBox>-->
		<div class="sheetBox">
			<div class="box box1">
				<span class="title">会后跟进及计划</span>
			</div>
			<div class="summary">
				<p class="notes">*注: 以下问题根据实际情况选择作答，或者点击“+会后跟进及计划” 自行添加（审核指标：不能一直只用数据库的，要有一定占比自行增加）</p>
				<!--<p>{{queList.msg1}}</p>
				<p>{{queList.msg2}}<spanstyle="color:deepskyblue;cursor:pointer;"
						@click="chooseOption2()">{{chooseApprove.length>0?chooseApprove.toString():'点击选择'}}</span></p>
				<pclass="notes">*注：以下问题根据自己需求选择作答，需要作答请勾选</p>-->
				<div v-for='(item,index) in queList.data' class="random" :class="{'chooseRandom':chooseArr[index]==index}" @click="chooseQue(index)">
					<div>{{index+1}}.
						<!--<el-radio:label="1"></el-radio>-->
						<span v-for="(itemChild,indexChild) in item" :class="{'chooseSpan':chooseArr[index] != index}">

							<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
								@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
								v-if="itemChild.position==1">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span><span>{{itemChild.question}}</span><span
								style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
								@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
								v-if="itemChild.position==2">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
						</span>
					</div>

				</div>
				
				<div class="summaryWrite">
					<div v-for="(item,index) in addSummaryList" class="summaryWriteOne">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.title">
						</el-input>
						<div>
							<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel(index)'>
								<template #reference>
									<button slot="reference" class="del">删除</button>
								</template>
							</el-popconfirm>
							<button class="choosePeople" @click='chooseOther(index)'>选择其他用户计划</button>
						</div>

					</div>
				</div>
				<div class="addSummary" @click="addSummary">
					+会后跟进及计划
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import RadioBox from "@/components/RadioBox.vue";
	import CheckBox from "@/components/CheckBox.vue";
	import OtherBox from "@/components/OtherBox.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	export default {
		name: "SummaryKe",
		data() {
			return {
				showMask: true,
				radio: 0,
				queList: '',
				ansList: [],
				chooseArr: [], //选择的问题索引值
				chooseApprove: [],
				addSummaryList: [], //增加的效果评价输入框列表
				show1: false,
				show2: false,
				show3: false,
				keyID:6
			}
		},
		components: {
			RadioBox,
			CheckBox,
			OtherBox
		},
		created() {
			// this.$eventBus.off('CheckChoose3')
			// this.$eventBus.off('otherChoose')
			// this.$eventBus.off('close')
			// this.$eventBus.off('allow')
			//单/多选完后传回来的值
			this.$eventBus.on('CheckChooseXue3', data => {
				console.log(data)
				console.log(this.ansList)
				this.show1 = false
				this.show2 = false
				this.show3 = false
				this.ansList[data.index.ind].ansChild[data.index.indChild].ans = data.choose
			})
			//单/多选完后传回来的值
			this.$eventBus.on('otherChooseXue2', data => {
				this.show1 = false
				this.show2 = false
				this.show3 = false
				this.addSummaryList[data.index] = {
					id: data.choose[0].id,
					title: data.choose[0].title
				}
			})
			
			this.$eventBus.on('close', data => {
				this.show1 = false
				this.show2 = false
				this.show3 = false
			})
			//遮罩层
			this.$eventBus.on('allowXue', data => {
				this.showMask = !data.allowData
				this.getListInfo()
			})
			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			// this.getListInfo()
		},
		beforeDestroy() {
			// this.$eventBus.off('CheckChoose3')
			this.$eventBus.off('close')
		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message: '请先完成会议通知板块内容',
					type: 'warning',
				})
			},
			//获取产品列表
			getListInfo() {
				this.chooseArr = []
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.memory_se_type2_5, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {


						if (_d.data.status == 200) {
							this.queList = _d.data.list

							_d.data.list.data.forEach((item, index) => {
								this.ansList.push({
									ans: '',
									ansChild: []
								})
								if (item[0].checked == 1) {
									this.chooseArr.push(index)
								} else {
									this.chooseArr.push(999)
								}

								item.forEach((itemChild, indexChild) => {
									// if (itemChild.result.length != 0) {
										this.ansList[index].ansChild.push({
											ans: itemChild.answer
										})
									// }
								})
							})
							this.addSummaryList = _d.data.list.data2.length == 0?[]:_d.data.list.data2
							console.log('dhjak',this.ansList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},
			//选择问题
			chooseQue(_index) {
				if (this.chooseArr.some(item => {
						return _index == item
					})) {
					this.chooseArr[_index] = 999
				} else {
					this.chooseArr[_index] = _index
				}
				console.log(this.chooseArr)
			},
			//单/多选选择
			chooseOption(_ind, _indChild, _select_type) {
				//this.ansList[_ind].ansChild[_indChild].ans=1111


				if (_select_type == 1) {
					this.show2 = true
					this.$eventBus.emit('showCheckBox', {
						ind: _ind,
						indChild: _indChild,
						CheckList: (this.queList.data[_ind])[_indChild].result,
						keyID:this.keyID
					})
				} else if (_select_type == 2) {
					this.show1 = true
					this.$eventBus.emit('showRadioBox', {
						ind: _ind,
						indChild: _indChild,
						radioList: (this.queList.data[_ind])[_indChild].result,
						keyID:this.keyID
					})
				}

			},
			//新增总结
			addSummary() {
				this.addSummaryList.push({
					title: ''
				})
			},
			//选择其他用户评价
			chooseOther(_ind) {
				this.show3 = true
				this.$eventBus.emit('showOtherBox', {
					type: 2,
					ind: _ind,
					meetID: this.meetID,
					sortType:2, // 2为会后跟进计划
					keyID:this.keyID
				})
			},
			// 
			yesDel(_index){
				this.addSummaryList.splice(_index, 1)
			}
		}
	}
</script>

<style scoped lang="scss">
	.sheet {
		background-color: #fff;
		padding: 15px 0;
		position: relative;
		// margin:0 0 15px 0;
		box-shadow:0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;
		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;
		}
		.chooseSpan{
			position: relative;
		}
		.chooseSpan::after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			z-index: 100;
			background-color: #000;
			width: 100%;
			height: 100%;
			opacity: 0;
		}

		.sheetBox {
			width: 1050px;
			margin: 0 auto;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;

				.title {
					display: inline-block;
					width: 160px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box1 {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.summary {
				width: 761px;
				margin: 0 auto;
				padding: 25px 0 0 0;

				p {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
				}

				.notes {
					color: #E30000;
				}

				.random {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
					position: relative;
					cursor: pointer;
				}
				.random::before {
					position: absolute;
					content: '';
					width: 15px;
					height: 15px;
					left: -25px;
					bottom: 0;
					top: 0;
					margin: auto;
					border: 1px solid #aaa;
					border-radius: 3px;
				}
				.chooseRandom {
					position: relative;
				}
				
				.chooseRandom::before {
					position: absolute;
					background-color: #1890FF;
				}
				
				.chooseRandom::after {
					position: absolute;
					left: -19px;
					top: 0;
					bottom: 0;
					margin: auto;
					content: '';
					width: 3px;
					height: 8px;
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					transform: rotate(45deg);
				}

				.summaryWrite {
					display: flex;
					flex-direction: column;
					//align-items:center;
					width: 100%;

					.summaryWriteOne {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;

						.del {
							margin: 15px 0;
							color: #E30000;
							cursor: pointer;
							background-color: #fff;
						}

						.choosePeople {
							color: #1890FF;
							margin: 0 0 0 30px;
							cursor: pointer;
							background-color: #fff;
						}
					}
				}

				.addSummary {
					width: 211px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					text-align: center;
					line-height: 28px;
					margin: 15px auto;
					cursor: pointer;
				}
			}

			.btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 20px 0;

				//justify-content:center;
				button:nth-child(1) {
					color: #D50000;
					margin: 0 100px 0 280px;
					cursor: pointer;
				}

				button:nth-child(2) {
					width: 97px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					cursor: pointer;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}
	}
</style>
