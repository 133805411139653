<template>
	<div class="sheet">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<QuestionBox @close="closeParent($event)" v-if="showQuestionBox" :meetID='meetID' :state='state' :ind='_ind'></QuestionBox>
		<AddQuestionBox :getTargetName="getTargetName"></AddQuestionBox>
		<div class="sheetBox">
			<h3>会议总结</h3>
			<div class="box box1">
				<div>
					<span class="title">会议中遇到问题互动及解答<span style="color: crimson;" v-if='memory.pro_on != 1'>({{memory.pro_on_name}})</span></span>
				</div>
				<div>
					<div v-for='(item,index) in queList' :key='index'>
						<div class="top">
							<span class="titles">问题{{index+1}}：</span>
							<span class="content"
								@click="chooseQuestion(index)">{{item.title?item.title:'请选择问题？'}}</span>
							<div @click="chooseQuestion(index)">
								<img src="../../assets/xing.png" alt="">
								<span>点击选择问题</span>
							</div>
						</div>
						<div class="bottom">
							<span class="titles">解答:</span>
							<p class="content" @click="chooseQuestion(index)">{{item.content?item.content:'请选择'}}</p>
						</div>
						<div class="btn">
							<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel(index)'>
								<template #reference>
									<button slot="reference" class="del">删除</button>
								</template>
							</el-popconfirm>
							<button @click="add">新增问答</button>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import QuestionBox from "@/components/QuestionBox.vue";
	import AddQuestionBox from "@/components/AddQuestionBox.vue";


	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	export default {
		name: "ProblemKe",
		data() {
			return {
				showMask: true,
				memory:{},
				showQuestionBox:false,
				radio: 1,
				meetID:'',
				_ind:'',
				queList: [{
					id: '',
					title: '',
					content: '',
				}], //问题和解答列表
				state:'',
			}
		},
		components: {
			QuestionBox,
			AddQuestionBox
		},
		created() {
			// this.$eventBus.off('Q_AChoose')
			// this.$eventBus.off('writeQue')
			// this.$eventBus.off('allow')
			//医院
			this.$eventBus.on('writeQue', data => {
				this.queList[data.ind] = {
					id: data.id,
					title: data.que,
					content: data.ans,
				}
			})
			this.$eventBus.on('allowBao', data => {
				this.showMask = !data.allowData
			})


			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.state = this.$route.query.sortID
			this.getWriteInfo()
		},
		beforeDestroy() {
			this.$eventBus.off('Q_AChoose')
			this.$eventBus.off('writeQue')
		},
		methods: {
			// 
			closeParent(value){
				console.log(value)
				this.showQuestionBox = value.show;
				if(value.state == 2){
					this.queList[value.ind].id = value.id
					this.queList[value.ind].title = value.que
					this.queList[value.ind].content = value.ans
				}
				
			},
			//提示
			prompt() {
				ElMessage({
					message: '请先完成会议通知板块内容',
					type: 'warning',
				})
			},
			//获取已经输入的值
			getWriteInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.update_pcmeet_page2_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {


						if (_d.data.status == 200) {
							this.memory = _d.data.list.memory
							if (_d.data.list.summary.length > 0) {
								this.queList = _d.data.list.summary
							}


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//选择问题
			chooseQuestion(_ind) {
				this.showQuestionBox = true
				this._ind = _ind
				// setTimeout(()=>{
				// 	this.$eventBus.emit('showQuestionBox', {
				// 		ind: _ind,
				// 		meetID: this.meetID
				// 	})
				// },100)
				
				
			},
			//删除问答
			yesDel(_ind) {
				if (this.queList.length > 1) {
					this.queList.splice(_ind, 1)
				} else {
					ElMessage({
						message: '仅剩一个时不可删除',
						type: 'warning',
					})
				}

			},
			//新增流程
			add() {
				this.queList.push({
					id: '',
					title: '',
					content: '',
				})
				console.log(222,this.queList)
			},
			//确认删除
			yes(_ind) {
				this.del(_ind)

			},
			//取消删除
			no() {

			},


		}
	}
</script>

<style scoped lang="scss">
	.sheet {
		background-color: #fff;
		padding: 15px 0;
		position: relative;
		// margin:15px 0;
		box-shadow:0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;
		}

		.sheetBox {
			width: 1050px;
			margin: 0 auto;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;

				.title {
					display: inline-block;
					width: 170px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box1 {
				font-size: 14px;
				color: #000;

				.top {
					margin: 15px 0;
					display: flex;
					flex-direction: row;
					align-items: center;

					.titles {
						width: 80px;
						text-align: right;
					}

					.content {
						width: 173px;
						height: 35px;
						border: 1px solid #d9d9d9;
						border-radius: 2px;
						overflow: hidden;
						line-height: 35px;
						padding: 0 5px;
						box-sizing: border-box;
						margin: 0 10px;
						cursor: pointer;

					}

					div {
						cursor: pointer;

						img {
							width: 15px;
							height: 13px;
							margin: 0 5px 0 0;
						}

						span {
							color: #1890ff;
							border-bottom: 1px solid #1890FF;
						}
					}
				}

				.bottom {
					font-size: 14px;
					color: #000;
					display: flex;
					flex-direction: row;

					.titles {
						width: 80px;
						text-align: right;
					}

					.content {
						cursor: pointer;
						width: 417px;
						height: 135px;
						border: 1px solid #d9d9d9;
						border-radius: 2px;
						overflow: auto;
						line-height: 35px;
						padding: 0 5px;
						box-sizing: border-box;
						margin: 0 10px;

					}

				}
			}



			.btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 20px 0;

				//justify-content:center;
				button:nth-child(1) {
					color: #D50000;
					margin: 0 100px 0 280px;
					cursor: pointer;
				}

				button:nth-child(2) {
					width: 97px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					cursor: pointer;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}
	}
</style>
