<template>
	<div>
		<div class="hello">
			<div class="head">
				<div class="logo">
					<img src='../assets/logo.png' />
					<span>{{info.username}}</span>
				</div>
				<div class="close" @click="quit">
					<img src="../assets/close.png" />
					<span>退出</span>
				</div>
			</div>
		</div>
		<!-- <div class="title2">
		<p>目标产品 : {{info.list.product_name}}</p>
		<p>参会人员 : {{info.list.attend_worker}}</p>
	</div> -->

		<div style="position: relative;border-top: 20px solid #eee;">
			<div class='title'>
				<p>上传信息</p>
				<span>上传对应信息<span style="color: red;opacity: 0.75;">(注：只上传需要上传的附件即可)</span></span>
			</div>
			<div class="title">
				<p class="titles">MAH拜访附件下载</p>
				<div class="down">
					<a :href="item.fileurl" v-for="(item,index) in info.mah_file"><span>{{item.name}}</span></a>
				</div>
			</div>
			<div class="image2">
				<p class="titles"><label>*</label>MAH拜访附件上传</p>
				<div class="up" v-for="(item,index) in info.list2">
					<div class="btn">
						<button class="upload-demos">上传文件
							<el-upload :action="files" :multiple='false' :data='fileData[index]' :on-success='function (response) { return upSuccess(response,index,item)}' accept='.doc,.docx'  class="input">
								<el-button type="primary">上传文件</el-button>
							</el-upload>
						</button>
						<span style="color: #f00;">{{item.name}},支持doc,docx,单个文件不大于10M</span>
						<div class="state" v-if="item.fileurl.length>0">
							<span><a :href="item.fileurl">已上传(点击下载)</a></span>
							<!-- <span>点击删除</span> -->
						</div>
					</div>
					<!-- <div class="imageList">
						<div v-for="(item,index) in info.list">
							<div v-if="item.file_type == 2">
								<div>
									<img src="../assets/icon_9.png" alt="">
									<img class="icon" src="../assets/icon_7.png" alt="" @click='dels(item.id,index)'>
								</div>
								<div>
									<a :href="item.imgurl">点击查看</a>
								</div>
							</div>
							<div v-else>
								<img :src="item.imgurl" alt="">
								<img class="icon" src="../assets/icon_7.png" alt="" @click='dels(item.id,index)'>
							</div>
						</div>
					</div> -->

				</div>
			</div>
			<div class='mask' v-if="this.info.state == 1"></div>
		</div>

		<div class="sub">
			<button @click="back">返回</button>
			<!-- <button @click="sub" v-if="info.list.state != 1">提交</button> -->
		</div>
	</div>
</template>


<script>
	// 引入七牛
	import * as qiniu from 'qiniu-js'
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'


	export default {
		name: "HelloWorld",
		props: {

		},
		data() {
			return {
				info: {
					
				}, // 用户信息
				dis: false,
				summary: '',
				fileList1: [],
				date: '',
				startTime: '', // 开始时间
				endTime: '', // 结束时间
				page: 1, // 页码
				dataList: [], // 数据
				imgList: [],
				files: API_LIST.uploadfile, // 文件
				fileData: [],
				icon_1: '',
				uploadData: {
					token: '',
					url: 'https://up-z2.qiniup.com'
				},
				jinduNum: 0,
				show: false,

			}

		},
		watch: {
			// summary(newData) {
			// 	console.log(newData)
			// 	const that = this;
			// 	console.log(this.info.list.text)
			// 	if (!this.info.list.text) {
			// 		return
			// 	}
			// 	if (newData) {
			// 		return
			// 	}
			// 	let obj = {
			// 		u_id: localStorage.getItem("u_id"),
			// 		department_meet_id: this.department_meet_id,
			// 		img_id: this.info.list.text.id
			// 	}

			// 	axios.post(API_LIST.del_content, obj, {
			// 			headers: {
			// 				'Content-Type': 'application/json'
			// 			} //加上这个
			// 		})
			// 		.then(_d => {
			// 			console.log(_d)
			// 			if (_d.data.status == 200) {
			// 				console.log(_d.data.msg)
			// 			} else {
			// 				ElMessage({
			// 					message: _d.data.msg,
			// 					type: 'warning',
			// 				})

			// 			}
			// 		})
			// 		.catch(function(error) {
			// 			console.log(error);
			// 		});

			// }
		},
		created() {

		},
		mounted() {
			if (!localStorage.getItem("u_id")) {
				this.$router.push({
					path: '/',
					query: {

					}
				});
			} else {
				this.department_meet_id = this.$route.query.department_meet_id
				// this.state = this.$route.query.state

				console.log(this.$route.query)
				this.getInfo()
			}
		},
		methods: {

			// randomString
			randomString() {
				return 1
			},
			// 获取数据列表
			getInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					visit_mah_id: this.department_meet_id,
				}

				axios.post(API_LIST.uploadPage, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							this.info = _d.data.list
							_d.data.list.list2.forEach(item =>{
								this.fileData.push({
									u_id:localStorage.getItem("u_id"),
									visit_mah_id:this.department_meet_id,
									words:item.words
								})
							})
							console.log(this.fileData)
							
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// ppt发送给后端
			submit_ppt(_name) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.department_meet_id,
					ppt: _name
				}

				axios.post(API_LIST.submit_ppt, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							// this.info.list.imgs.splice(_ind, 1)
							this.info.list.ppt = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 跳页
			jump(_id) {
				if (_id != 4) {
					ElMessage({
						message: '该功能暂未开放',
						type: 'warning',
					})
					return
				}
				this.$router.push({
					path: '/Home',
					query: {
						id: _id,
					}
				});

			},
			// 更改日期
			dateChange(e) {
				console.log(e)
				this.startTime = e[0]
				this.endTime = e[1]
				this.page = 1;
				this.getList()
			},
			// 重置
			reset() {
				this.date = '';
				this.startTime = '';
				this.endTime = '';
				this.page = 1;
				this.getList()
			},
			// 点击跳页
			handleSizeChange() {
				console.log(this.page)
				this.getList()
			},
			// 输入跳页
			handleCurrentChange() {
				console.log(this.page)
				this.getList()
			},
			// 跳转到上传页
			upData(_state) {
				this.$router.push({
					path: '/UpData',
					query: {
						state: _state
					}
				});
			},
			// 上传前
			onchangemd(e, _words) {
				console.log(_words)
				const that = this;
				this.fileData = {
					u_id:localStorage.getItem("u_id"),
					visit_mah_id:this.department_meet_id,
					words:_words
				}
				console.log(this.fileData)
			},
			// 上传成功
			upSuccess(e,_index,_item) {
				console.log(111, _index)
				console.log(333, e)
				if (e.status == 200) {
					ElMessage({
						message: e.msg,
						type: 'success',
					})
					this.info.list2.splice(_index,1,{
						fileurl:e.list.fileurl,
						is_qualint: _item.is_qualint,
						name: _item.name,
						words: _item.words
					})
					// if (e.list.img_type == 2) {
					// 	console.log(this.info.list.imgs)
					// 	this.info.list.imgs.push(e.list)
					// } else {
					// 	this.info.list.imgs2.push(e.list)
					// }

				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			// 删除图片
			del(_id, _ind, _state) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.department_meet_id,
					id: _id,
					file_type: 1, // 1是图片，2是其他文件
				}

				axios.post(API_LIST.del_img, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							if (_state == 2) {
								this.info.list.imgs.splice(_ind, 1)
							} else {
								this.info.list.imgs2.splice(_ind, 1)
							}


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 删除文件
			dels(_id, _ind) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.department_meet_id,
					id: _id,
					file_type: 2
				}

				axios.post(API_LIST.del_img, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.info.list.other_file.splice(_ind, 1)
							console.log(this.info.list.other_file)

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			loads(files, fileList) {
				// console.log(11111, '加载中')
				// this.load = this.$loading({ fullscreen: true })
			},
			// 单文件超限
			handleExceed(files, fileList) {
				// this.load = this.$loading({ fullscreen: true })
				console.log(files)
				console.log(Object.keys(files).length)
				if (Object.keys(files).length > 1) {
					ElMessage({
						message: '请单个文件上传',
						type: 'warning',
					})
					return
				}
				console.log('ppt:', this.info.list.ppt)
				if (this.info.list.ppt.length > 0) {
					ElMessage({
						message: '请先删除已上传的ppt文件',
						type: 'warning',
					})
					return
				}

			},
			del_ppt(_list) {
				console.log(999, _list)
				// return
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.department_meet_id,
				}

				axios.post(API_LIST.del_ppt, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.info.list.ppt = _d.data.list;
							this.fileList1 = [];


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 失败
			errors(files, fileList) {
				console.log(66666)
			},
			// 返回
			back() {
				this.$router.go(-1)
			},
			// 退出
			quit() {
				window.sessionStorage.clear();
				this.$router.push({
					path: '/',
					query: {

					}
				});
			},
			// 
			changes(e) {
				console.log(e)
			},
			// 提交科室会
			sub() {
				console.log(this.info.list)
				this.info.list.ppt
				if (!this.info.list.ppt) {
					ElMessage({
						message: '请先上传ppt',
						type: 'success',
					})
					return
				}
				if (this.info.list.type == 1 && this.info.list.imgs.length < 2) {
					ElMessage({
						message: '网络培训图片至少上传两张',
						type: 'success',
					})
					return
				}
				if (this.info.list.type == 2 && this.info.list.imgs.length < 2) {
					ElMessage({
						message: '会议图片至少上传两张',
						type: 'success',
					})
					return
				}
				if (this.info.list.type == 2 && this.info.list.imgs2.length < 1) {
					ElMessage({
						message: '签到图片至少上传一张',
						type: 'success',
					})
					return
				}
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.department_meet_id,
					summary: this.summary,
					file_type: 3,
					submit_type: 2
				}

				axios.post(API_LIST.submit_content, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.back()

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.jindu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: #fff;
		opacity: 0.8;
		z-index: 1000;

		.huan {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			width: 250px;
			height: 250px;
		}
	}

	.title2 {
		padding: 20px 150px;
		box-sizing: border-box;
		border-bottom: 20px solid #f0f2f5;

		p {
			margin: 0 0 10px 0;
			font-size: 15px;
			font-weight: 800;
		}
	}

	.hui {
		background-color: #ccc !important;
		color: #000 !important;
	}

	.mask {
		width: 100%;
		height: 100%;
		background-color: #fff;
		opacity: 0;
		left: 0;
		top: 0;
		position: absolute;
		z-index: 1000000;
	}

	.head {
		height: 48px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 1px solid #f0f2f5;

		.logo,
		.close {
			img {
				height: 26px;
			}

			span {
				margin: 0 25px 0 10px;
				font-size: 14px;
			}
		}

		.close {
			span {
				color: #0073FF;
			}
		}

		.close:hover {
			cursor: pointer;
		}
	}

	.title {
		width: 100%;
		padding: 30px 150px;
		box-sizing: border-box;
		border-bottom: 20px solid #f0f2f5;

		p {
			font-size: 20px;
			font-weight: 800;
			margin: 0 0 5px 0;
		}

		span {

			font-size: 14px;
			font-weight: 500;
			color: #000;
			// opacity: 0.65;
		}

		.down {
			display: flex;
			flex-direction: row;
			margin: 20px 0;

			span {
				margin: 0 20px 0 0;
				color: #0073FF;
				border-bottom: 1px solid #0073FF;
				cursor: pointer;
			}
		}
	}


	.image,
	.image2,
	.summary,
	.ppt {
		display: flex;
		flex-direction: column;
		padding: 0 150px;
		box-sizing: border-box;

		.titles {
			font-size: 16px;
			padding: 15px 0;
			border-bottom: 1px solid #eee;

			label {
				color: #FF0000;
				margin: 0 5px 0 0;
			}
		}

		.up {
			display: flex;
			flex-direction: column;
			padding: 0 100px;
			box-sizing: border-box;
			margin: 10px 0;

			.btn {
				display: flex;
				flex-direction: row;
				align-items: center;

				.upload-demos {
					width: 110px;
					height: 37px;
					background: #1890ff;
					color: #fff;
					text-align: center;
					line-height: 37px;
					position: relative;

					.input {
						position: absolute;
						left: 0;
						bottom: 0;
						width: 110px;
						height: 37px;
						opacity: 0;
					}
				}

				span {
					color: #000;
					opacity: 0.65;
					font-size: 14px;
					margin: 0 0 0 10px;
				}
				.state{
					a:nth-child(1){
						color: #33FF00;
					}
					span:nth-child(2){
						color: #FF0000;
						cursor: pointer;
					}
				}
			}

			.imageList {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 20px 0;

				div {
					position: relative;
					margin: 0 15px 0 0;

					img {
						width: 90px;
						height: 90px;
					}

					.icon {
						width: 22px;
						height: 22px;
						position: absolute;
						right: -11px;
						top: -11px
					}
				}

			}
		}
	}

	.ppt a:hover {
		color: #0073FF;
	}

	.ppt .dels:hover {
		color: #0073FF;
	}

	.summary {
		.up {
			display: flex;
			flex-direction: row;

			p {
				padding: 0;
			}

			textarea {
				width: 50%;
				margin: 0 0 0 10px;
				border: 1px solid #ccc;
				border-radius: 4px;
				box-sizing: border-box;
				padding: 15px;
				outline-color: #ddd;
				outline-style: 1px solid #ddd;
			}
		}
	}

	.ppt {
		padding-bottom: 20px;
		box-sizing: border-box;

		.upload-demo {
			width: 100%;

			// margin: 0 0 0 100px;
			// width: 80%;
			::v-deep .el-upload {
				width: 100% !important;
			}

			::v-deep .el-upload-dragger {
				width: 100% !important;

				img {
					margin: 10px 0 0 -40px;
				}
			}

			.el-upload-dragger .el-upload__text {
				margin: 60px 0 0 0 !important;
			}
		}

	}

	.sub {
		background-color: #fff;
		padding: 10px 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border-top: 20px solid #f0f2f5;
		// position: absolute;
		// bottom: 0;
		// width: 100%;
		box-sizing: border-box;

		button {
			width: 65px;
			height: 32px;
			background: #ffffff;
			border: 1px solid #d9d9d9;
			border-radius: 2px;
			cursor: pointer;
		}

		button:nth-child(2) {
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 0 0 0 20px;
		}
	}
</style>
