<template>
	<div>
		<Mask></Mask>
		<div class="box" v-loading="loading">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">{{meetSort.id == 1?'药店':meetSort.id == 2?'商业':meetSort.id == 3?'连锁':'医院'}}列表</div>
			<div class="tops">
				<div class="search">
					<span>目标{{meetSort.id == 1?'药店':meetSort.id == 2?'商业':meetSort.id == 3?'连锁':'医院'}}</span>
					<input v-model='searchVal' type="text" placeholder="请输入关键字">
					<button @click='search'>搜索</button>
				</div>
				<div class="addHospital" @click='addHospital'>+添加{{meetSort.id == 1?'药店':meetSort.id == 2?'商业':meetSort.id == 3?'连锁':'医院'}}</div>
			</div>
			<ul>
				<li @click="choose(index)" v-for="(item,index) in menuList" :class="{'choose':index==chooseNum}">
					{{item.name}}
				</li>
			</ul>
			<div class="fen">
				<el-pagination @size-change="handleSizeChange " @current-change="handleCurrentChange"
					:current-page.sync="currentPage" :page-size="10" small="small" layout="prev,pager,next,jumper"
					:total="totalNum">
				</el-pagination>
			</div>
			<button class="submit" @click.stop="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";

	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "HospitalBox",
		data() {
			return {
				show: false,
				searchVal: '', //搜索词
				menuList: '',
				hospitalID: '',
				chooseNum: 9999, //默认选中
				page: 1,
				type: '', //类型id
				totalNum: '',
				currentPage: 1,
				loading: false,
				sortID:'',
				//small,
			}
		},
		components: {
			Mask,

		},
		props:['meetSort'],
		created() {
			// this.$eventBus.off('show3')
			// this.$eventBus.on('show3', data => {
			// 	this.show = data.show;
			// 	this.getListInfo()
			// })
			this.sortID = this.$route.query.sortID
			console.log(this.meetSort)
			this.getListInfo()
		},
		beforeDestroy() {
			this.$eventBus.off('show3')
		},
		methods: {
			//选中
			choose(_ind) {
				this.chooseNum = _ind
			},
			//关闭
			close() {
				this.$emit('close',{
					state:2,
					show:false
				})
			},
			//请求协议列表
			getListInfo() {
				this.chooseNum = 9999;
				this.loading = true;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					page: this.page,
					keywords: this.searchVal,
					customer_type_id: 2, //医院为2
				}
				
				let a = qs.stringify(obj)
				let api = API_LIST.sel2
				if(this.meetSort){
					if(this.meetSort.id == 1){
						api = API_LIST.sel_drugstore6
					}else if(this.meetSort.id == 2){
						api = API_LIST.sel_business6
					}else if(this.meetSort.id == 3){
						api = API_LIST.sel_chain6
					}
				}

				axios.post(api, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						this.loading = false;
						if (_d.data.status == 200) {
							console.log(_d.data.list)
							this.menuList = _d.data.list.list;
							if (_d.data.list.list.length > 0) {
								this.hospitalID = _d.data.list.list[0].id;
							} else {
								this.hospitalID = '';
							}

							this.totalNum = _d.data.list.count
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//搜索
			search() {
				this.page = 1;
				this.currentPage = 1;
				this.getListInfo()
			},
			//切页
			handleSizeChange(number) {
				this.chooseNum = 9999;
				this.currentPage = number;
				this.page = number
				this.getListInfo()
			},
			//跳页
			handleCurrentChange(number) {
				this.chooseNum = 9999;
				this.currentPage = number;
				this.page = number
				this.getListInfo()
			},
			//新建医院
			addHospital() {
				this.$emit('close',{
					state:2,
					show:false
				})
				this.$eventBus.emit('show4', {
					data: true,
					state:this.meetSort.id == 1?4:this.meetSort.id == 2?3:this.meetSort.id == 3?'':2, // 2是医院 3是商业 4是药店 
					meetSort:this.meetSort,
					// words:this.meetSort.id == 1?'dian':''
				})
			},
			//确定
			submit() {
				if (this.hospitalID) {
					this.chooseHospital()
				} else {
					ElMessage({
						message: '请选择'+this.meetSort.id == 1?'药店':this.meetSort.id == 2?'商业':this.meetSort.id == 3?'连锁':'医院',
						type: 'warning',
					})
				}
			},
			//确定医院
			chooseHospital() {
				this.hospital = this.menuList[this.chooseNum].name;
				this.address = {
					province:this.menuList[this.chooseNum].province,
					city:this.menuList[this.chooseNum].city,
					address:this.menuList[this.chooseNum].address,
					
				}
				this.$emit('close',{
					state:1,
					show:false,
					hospitalName: this.hospital,
					address:this.address
				})
			},

		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 110px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 828px;
		height: 554px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.tops {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 750px;
			margin: 0 0 20px 0;

			.search {
				display: flex;
				flex-direction: row;
				align-items: center;

				span {
					font-size: 14px;
				}

				input {
					width: 272px;
					height: 32px;
					background: #ffffff;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					margin: 0 15px;
					padding: 0 0 0 5px;
				}

				button {
					width: 65px;
					height: 32px;
					background: #1890ff;
					border-radius: 2px;
					color: #fff;
				}
			}

			.addHospital {
				width: 97px;
				height: 28px;
				background: #e0f1ff;
				border-radius: 3px;
				text-align: center;
				line-height: 28px;
				font-size: 14px;
				color: #1890FF;
				cursor: pointer;
			}
		}

		ul {
			width: 90%;
			margin: 0 auto;
			height: 345px;

			//overflow:auto;
			li {
				width: 100%;
				height: 25px;
				line-height: 25px;
				border-radius: 2px;

				margin-bottom: 10px;
				//text-align:center;
				cursor: pointer;
				padding: 0 0 0 20px;
				box-sizing: border-box;
			}

			.choose {
				background-color: #1890ff;
				color: #fff;
			}
		}

		.submit {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
