<template>
	<div v-if="show">
		<Mask></Mask>
		<div class="box" v-loading="loading">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">会议目标</div>
			<div class='add'>
				<p class='addKe' @click="addTarget">+新增会议目标</p>
			</div>
			<ul>
				<li @click="choose(item.name,item.id)" v-for="(item,index) in menuList"
					:class="chooseArr.findIndex(item2=>item2.name===item.name)!=-1?'choose':''">
					{{item.name}}
				</li>
				<li v-if="menuList.length==0">暂无数据</li>
			</ul>
			<button @click.stop="submit">确定</button>
		</div>
		<el-dialog title="新增会议目标" v-model="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="会议目标内容" :label-width="formLabelWidth">
					<el-input v-model="newTargetName" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="text-align:end!important;">
				<el-button @click="dialogFormVisible=false">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "TargetBox",
		data() {
			return {
				show: false,
				menuList: '',
				chooseArr: [],
				keID: '', //科室id
				chooseNum: 0, //默认选中
				page: 1,
				type: '', //类型id
				dialogFormVisible: false,
				newTargetName: '', //新目标名称
				formLabelWidth: '120px',
				loading:false,
				sortID:999,
			}
		},
		components: {
			Mask
		},
		props: ['getTargetName'],
		created() {
			// this.$eventBus.off('showTargetBox')
			this.$eventBus.on('showTargetBox', data => {
				if(data.sortID){
					this.sortID = data.sortID;
				}
				this.show = data.show;
				this.meetID = data.meetID;
				this.getListInfo()
			})

		},
		methods: {
			//选中
			choose(_name, _id) {
				let _ind = this.chooseArr.findIndex(item => item.name === _name)
				if (_ind != -1) {
					this.chooseArr.splice(_ind, 1)
				} else {
					this.chooseArr.push({
						name: _name,
						id: _id
					})

				}

			},
			//关闭
			close() {
				this.show = false
			},
			//请求协议列表
			getListInfo() {
				this.loading = true
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID
				}
				let a = qs.stringify(obj)
				let api = API_LIST.sel_purpose
				if(this.sortID == 2 || this.sortID == 7){
					api = API_LIST.sel_purpose4
				}else if(this.sortID == 8){
					api = API_LIST.sel_purpose5
				}if(this.sortID == 3){
					api = API_LIST.sel_purpose6
				}
				console.log(api)
				
				axios.post(api, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						this.loading = false;
						if (_d.data.status == 200) {
							this.menuList = _d.data.list;

							//(this.menuList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//确定
			submit() {
				if (this.chooseArr.length > 0) {

					this.sendTargetName()
				} else {
					ElMessage({
						message: '请选择会议目标',
						type: 'warning',
					})
				}
			},
			//把值传给父组件
			sendTargetName() {

				this.show = false
				this.getTargetName(this.chooseArr)
				this.chooseArr = []
			},
			//新建科室名称
			addTarget() {
				this.chooseArr = []
				this.dialogFormVisible = true;
			},
			//确定添加科室
			confirm() {
				if (this.newTargetName) {
					this.menuList.unshift({
						id: '',
						name: this.newTargetName
					})
					this.dialogFormVisible = false
				} else {
					ElMessage({
						message: '请填写新会议目标',
						type: 'warning',
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 628px;
		height: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.add {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			width: 100%;
			padding: 0 30px;
			box-sizing: border-box;
			margin: 0 0 15px 0;

			.addKe {
				width: 124px;
				height: 28px;
				background: #e0f1ff;
				border-radius: 3px;
				color: #1890FF;
				text-align: center;
				line-height: 28px;
				cursor: pointer;
			}
		}


		ul {
			width: 90%;
			margin: 0 auto;
			height: 310px;
			overflow: auto;

			li {
				width: 100%;
				height: 32px;
				line-height: 32px;
				border-radius: 2px;

				margin-bottom: 10px;
				text-align: center;
				cursor: pointer;
			}

			.choose {
				background-color: #1890ff;
				color: #fff;
			}
		}

		.dialog-footer {
			text-align: end !important;
		}

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
