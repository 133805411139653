<template>
	<div class="notice">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<AddHospitalBox @show3='show3($event)'></AddHospitalBox>
		<HospitalBox v-if='showHospitalBox' :meetSort='{id:3}' @close="closeHospitalBox($event)"></HospitalBox>
		<ThemeBox :getThemeName="getThemeName"></ThemeBox>
		<TargetBox :getTargetName="getTargetName"></TargetBox>
		<RadioBox v-if='show1' :status1='status1' @close2="close2" :getRadioName="getRadioName"></RadioBox>
		<CheckBox v-if='show2' :status2='status2' @close2="close2" :getCheckName="getCheckName"></CheckBox>
		<OtherBox v-show='show4' :status3='status3' @close2="close2" :getOtherName="getOtherName"></OtherBox>
		<div class="noticeBox">
			<h3>门店销售前三排名及心得分享<span v-if="promo.field_state != 1" style="color: #f00;">{{promo.field_state == 1?'':'(非必填)'}}</span></h3>
			<div class="box box1">
				<!-- <span class="title">门店销售前三排名及心得分享</span> -->
				<!-- <el-select v-model="productName.name" filterable @change='changeProduct' placeholder="请选择" size="small">
					<el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.name">
					</el-option>
				</el-select> -->
				<ul>
					<li>排名</li>
					<li>门店</li>
					<li>完成率</li>
					<li>心得分享</li>
				</ul>
				<ul v-for="(item,index) in experienceList" class="pai">
					<li>{{index+1}}</li>
					<li>{{item.shop_name}}</li>
					<li>{{item.complete_mix}}</li>
					<textarea name="" id="" cols="30" rows="10" v-model="item.summary"></textarea>
				</ul>
			</div>
		</div>
		<div class="noticeBox">
			<h3>活动情况</h3>
			<div class="box box4">
				<span class="title">活动预计费用(万元)</span>
				<input type="number" v-model="act_expect_cost" placeholder="请填写">
			</div>
			<div class="box box4">
				<span class="title">活动实际核销费用(万元)</span>
				<input type="number" v-model="act_real_cost" placeholder="请填写">
			</div>
			<div class="box box4">
				<span class="title">活动后实际库存量(万盒)</span>
				<input type="number" v-model="acted_real_stock" placeholder="请填写">
			</div>
		</div>



		<div class="noticeBox">
			<h3>活动情况</h3>
			<div class="box box4">
				<span class="title">总结</span>
				<el-radio-group v-model="radio2" @change="radioChange2">
					<el-radio :label="1">回答问题</el-radio>
					<el-radio :label="2">上传文件</el-radio>
				</el-radio-group>
			</div>
			<div class="box box4" v-if="radio2 == 1">
				<div class="summary">
					<ul>
						<ol v-for="item in queList.data3">{{item.key}}.{{item.txt}}</ol>
					</ul>
					<p class="notes">*注: 包括但不限于：与预期的差异及差异产生的根源/收获与不足/改善与建议等，若超预算详细说明超出预算的原因(含物料)</p>

					<div v-for='(item,index) in queList.data' class="random"
						:class="{'chooseRandom':chooseArr[index]==index}" @click="chooseQue(index)">
						<div>{{index+1}}.
							<span v-for="(itemChild,indexChild) in item"
								:class="{'chooseSpan':chooseArr[index] != index}">

								<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==1">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span><span>{{itemChild.question}}</span><span
									style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==2">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
							</span>
						</div>

					</div>

					<div class="summaryWrite">
						<div v-for="(item,index) in addSummaryList" class="summaryWriteOne">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.title">
							</el-input>
							<div>
								<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel(index)'>
									<template #reference>
										<button slot="reference" class="del">删除</button>
									</template>
								</el-popconfirm>
								<button class="choosePeople" @click='chooseOther(index)'>选择其他用户计划</button>
							</div>

						</div>
					</div>
					<div class="addSummary" @click="addSummary">
						+活动推广具体实操方案
					</div>

				</div>
			</div>
			<div v-if="radio2 == 2">
				<div class="up">
					<div class="btns">
						<button class="upload-demos">上传文件
							<el-upload :action="files" :multiple='false' :data='fileData' :on-success='upSuccess'
								:before-upload='onchangemd' class="input">
								<el-button type="primary">上传文件</el-button>
							</el-upload>
						</button><span>支持ppt/pdf/jpg/jpeg/png格式，每个文件不大于10M</span>
					</div>
					<div class="fileList">
						<div v-for="(item,index) in fileList" class="fileListOne">
							<img src="../../assets/fileImg.png" alt="">
							<span>{{item.url2}}</span>
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del(item.id,index)'>
						</div>
					</div>
				</div>
			</div>


		</div>

		<div class="noticeBox">
			<h3>活动效果自评</h3>
			<div class="box box4">
				<div class="summary">
					<!-- <p class="notes">*注: 以下问题根据实际情况选择作答，或者点击“+会后跟进及计划” 自行添加（审核指标：不能一直只用数据库的，要有一定占比自行增加）</p> -->

					<div v-for='(item,index) in queList2.data' class="random"
						:class="{'chooseRandom':chooseArr2[index]==index}" @click="chooseQue_2(index)">
						<div>{{index+1}}.
							<span v-for="(itemChild,indexChild) in item"
								:class="{'chooseSpan':chooseArr2[index] != index}">

								<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption_2(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==1">{{ansList2[index].ansChild[indexChild].ans?ansList2[index].ansChild[indexChild].ans.toString():'点击选择'}}</span><span>{{itemChild.question}}</span><span
									style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption_2(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==2">{{ansList2[index].ansChild[indexChild].ans?ansList2[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
							</span>
						</div>

					</div>

					<div class="summaryWrite">
						<div v-for="(item,index) in addSummaryList2" class="summaryWriteOne">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.title">
							</el-input>
							<div>
								<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel_2(index)'>
									<template #reference>
										<button slot="reference" class="del">删除</button>
									</template>
								</el-popconfirm>
								<button class="choosePeople" @click='chooseOther_2(index)'>选择其他用户计划</button>
							</div>

						</div>
					</div>
					<div class="addSummary" @click="addSummary_2">
						+会后跟进及计划
					</div>

				</div>
			</div>
		</div>

		<div class="noticeBox">
			<h3>下步计划</h3>
			<div class="box box4">
				<div class="summary">
					<!-- <p class="notes">*注: 以下问题根据实际情况选择作答，或者点击“+会后跟进及计划” 自行添加（审核指标：不能一直只用数据库的，要有一定占比自行增加）</p> -->

					<div v-for='(item,index) in queList3.data' class="random"
						:class="{'chooseRandom':chooseArr3[index]==index}" @click="chooseQue_3(index)">
						<div>{{index+1}}.
							<span v-for="(itemChild,indexChild) in item"
								:class="{'chooseSpan':chooseArr3[index] != index}">

								<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption_3(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==1">{{ansList3[index].ansChild[indexChild].ans?ansList3[index].ansChild[indexChild].ans.toString():'点击选择'}}</span><span>{{itemChild.question}}</span><span
									style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption_3(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==2">{{ansList3[index].ansChild[indexChild].ans?ansList3[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
							</span>
						</div>

					</div>

					<div class="summaryWrite">
						<div v-for="(item,index) in addSummaryList3" class="summaryWriteOne">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.title">
							</el-input>
							<div>
								<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel_3(index)'>
									<template #reference>
										<button slot="reference" class="del">删除</button>
									</template>
								</el-popconfirm>
								<button class="choosePeople" @click='chooseOther_3(index)'>选择其他用户计划</button>
							</div>

						</div>
					</div>
					<div class="addSummary" @click="addSummary_3">
						+会后跟进及计划
					</div>

				</div>
			</div>
		</div>

		<div class="save">
			<span>当前填写页</span>
			<button @click="save">保存</button>
		</div>
		<div class="sub"
			v-if='status_state.act_plan == 1 && status_state.act_sum == 1 && status_state.act_file == 1&&status_state.state != 1'>
			<button @click="submit">提交</button>
		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import AddHospitalBox from "@/components/Promotion/AddHospitalBox.vue";
	import HospitalBox from "@/components/Promotion/HospitalBox.vue";
	import KeBox from "@/components/KeBox.vue";
	import ThemeBox from "@/components/ThemeBox.vue";
	import TargetBox from "@/components/Promotion/TargetBox.vue";
	import RadioBox from "@/components/Promotion/RadioBox.vue";
	import CheckBox from "@/components/Promotion/CheckBox.vue";
	import OtherBox from "@/components/Promotion/OtherBox.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {

		regionData

	} from "element-china-area-data";
	export default {
		name: "NoticeKe",
		data() {
			return {
				show1: false,
				show2: false,
				show4: false,
				showMask: false,
				promo:{field_state:''},
				experienceList: '',
				pcTextArr: regionData,
				selectedOptions: [],
				address: '', // 详细地址
				showHospitalBox: false, // 显示医院的列表
				radio: 1,
				radio2: 1,
				act_expect_cost: '', // 活动预计费用
				act_real_cost: '', // 活动实际核销费用
				acted_real_stock: '', // 活动后实际库存量

				status1: 1,
				status2: 1,
				status3: 1,

				queList: '',
				addSummaryList: [],
				chooseArr: [],
				ansList: [],

				files: API_LIST.uploadNew_8, // 上传任务分解明细文件
				fileData: {

				},

				queList2: '',
				addSummaryList2: [],
				chooseArr2: [],
				ansList2: [],

				queList3: '',
				addSummaryList3: [],
				chooseArr3: [],
				ansList3: [],


				getCheckName: {},
				getRadioName: {},
			}
		},
		components: {
			HospitalBox,
			AddHospitalBox,
			KeBox,
			ThemeBox,
			TargetBox,
			RadioBox,
			CheckBox,
			OtherBox
		},
		props: ['status_state'],
		created() {
			//单/多选完后传回来的值
			this.$eventBus.on('CheckChoose_2', data => {
				this.show1 = false
				this.show2 = false
				this.show4 = false
				console.log('++++',this.ansList)
				console.log('////',data)
				if (this.status2 == 1) {
					this.ansList[data.index.ind].ansChild[data.index.indChild].ans = data.choose
				} else if (this.status2 == 2) {
					console.log(this.ansList2)
					console.log(data.index)
					console.log(data.index.indChild)
					this.ansList2[data.index.ind].ansChild[data.index.indChild].ans = data.choose
				} else if (this.status2 == 3) {
					console.log(data.choose)
					this.ansList3[data.index.ind].ansChild[data.index.indChild].ans = data.choose
					console.log(this.ansList3)
					console.log(this.queList3.data)

				}

			})


			this.$eventBus.on('otherChoose', data => {
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
				console.log('=====',data)
				if(data.ue == 1){
					this.addSummaryList[data.index] = {
						id: data.choose[0].id,
						title: data.choose[0].question
					}
				}else if(data.ue == 2){
					this.addSummaryList2[data.index] = {
						id: data.choose[0].id,
						title: data.choose[0].question
					}
				}else if(data.ue == 3){
					this.addSummaryList3[data.index] = {
						id: data.choose[0].id,
						title: data.choose[0].question
					}
				}
				

			})
			
			//关闭
			this.$eventBus.on('close', data => {
				console.log(222)
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
			})

			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.sortID = this.$route.query.sortID
			this.fileData = {
				u_id: localStorage.getItem("u_id"),
				promo_id: this.meetID,
				img_type: 3,
				type: 2,
				pub_key: 'se',
				file_type: 4,
				submit_type: 2
			}
			if (this.status_state.state == 1 || this.status_state.act_plan != 1) {
				this.showMask = true
			} else {
				console.log(this.status_state)
			}
			// this.getListInfo();
			this.getListInfo2();
			this.getListInfo3();
			this.getListInfo4();
			this.getWriteInfo();
		},
		watch: {


		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message: '该区域禁止更改',
					type: 'warning',
				})
			},
			//获取心得列表
			getListInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
					submit_type: 1,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.promo_sum_page, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							console.log(_d)
							this.experienceList = _d.data.list.slice(1,3)
							this.experienceList.forEach(item => {
								item.summary = ''
							})

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取问题和选项
			getListInfo2() {

				this.chooseArr = []
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.promo_sum_data, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							this.queList = _d.data.list
							console.log('=====', this.queList)
							_d.data.list.data.forEach((item, index) => {
								this.ansList.push({
									ans: '',
									ansChild: []
								})
								if (item[0].checked == 1) {
									this.chooseArr.push(index)
								} else {
									this.chooseArr.push(999)
								}

								item.forEach((itemChild, indexChild) => {
									// if (itemChild.result.length != 0) {
									this.ansList[index].ansChild.push({
										ans: itemChild.answer
									})
									// }
								})
							})
							this.addSummaryList = _d.data.list.data2.length == 0 ? [] : _d.data.list.data2
							console.log('dhjak', this.ansList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取问题和选项
			getListInfo3() {

				this.chooseArr = []
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.act_eva_data, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							this.queList2 = _d.data.list
							console.log('=====', this.queList2)
							_d.data.list.data.forEach((item, index) => {
								this.ansList2.push({
									ans: '',
									ansChild: []
								})
								if (item[0].checked == 1) {
									this.chooseArr2.push(index)
								} else {
									this.chooseArr2.push(999)
								}

								item.forEach((itemChild, indexChild) => {
									// if (itemChild.result.length != 0) {
									this.ansList2[index].ansChild.push({
										ans: itemChild.answer
									})
									// }
								})
							})
							this.addSummaryList2 = _d.data.list.data2.length == 0 ? [] : _d.data.list.data2
							console.log('dhjak', this.ansList2)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取问题和选项
			getListInfo4() {

				this.chooseArr = []
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.next_plan_data, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							this.queList3 = _d.data.list
							console.log('=====', this.queList2)
							_d.data.list.data.forEach((item, index) => {
								this.ansList3.push({
									ans: '',
									ansChild: []
								})
								if (item[0].checked == 1) {
									this.chooseArr3.push(index)
								} else {
									this.chooseArr3.push(999)
								}

								item.forEach((itemChild, indexChild) => {
									// if (itemChild.result.length != 0) {
									this.ansList3[index].ansChild.push({
										ans: itemChild.answer
									})
									// }
								})
							})
							this.addSummaryList3 = _d.data.list.data2.length == 0 ? [] : _d.data.list.data2
							console.log('dhjak', this.ansList3)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取已经输入的值
			getWriteInfo() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.promo_page2, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						console.log(11111, _d.data.list)
						if (_d.data.status == 200) {
							this.promo = _d.data.list.promo
							this.experienceList = _d.data.list.promo.share.slice(0,3)
							this.act_expect_cost = _d.data.list.promo.act_expect_cost
							this.act_real_cost = _d.data.list.promo.act_real_cost
							this.acted_real_stock = _d.data.list.promo.acted_real_stock


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},
			//选择问题
			chooseQue(_index) {

				if (this.chooseArr.some(item => {
						return _index == item
					})) {
					this.chooseArr[_index] = 999
				} else {
					this.chooseArr[_index] = _index
				}
			},

			//单/多选选择
			chooseOption(_ind, _indChild, _select_type) {

				this.status1 = 1
				this.status2 = 1

				if (_select_type == 1) {
					this.show2 = true
					this.getCheckName = {
						ind: _ind,
						indChild: _indChild,
						CheckList: (this.queList.data[_ind])[_indChild].result
					}
					// this.$eventBus.emit('showCheckBox', {
					// 	ind: _ind,
					// 	indChild: _indChild,
					// 	CheckList: (this.queList.data[_ind])[_indChild].result
					// })
				} else if (_select_type == 2) {
					this.show1 = true
					this.getRadioName = {
						ind: _ind,
						indChild: _indChild,
						radioList: (this.queList.data[_ind])[_indChild].result
					}
					// this.$eventBus.emit('showRadioBox', {
					// 	ind: _ind,
					// 	indChild: _indChild,
					// 	radioList: (this.queList.data[_ind])[_indChild].result
					// })
				}

			},
			//新增总结
			addSummary() {
				this.addSummaryList.push({
					id: '',
					title: ''
				})
			},
			//删除自己添加的总结
			yesDel(_index) {

				this.addSummaryList.splice(_index, 1)
			},
			//选择其他用户评价
			chooseOther(_ind) {
				this.show4 = true;
				this.$eventBus.emit('showOtherBox2', {
					ind:_ind,
					ue:1
				})
			},
			//上传文件
			upSuccess2(e) {
				if (e.status == 200) {
					ElMessage({
						message: e.msg,
						type: 'success',
					})

					this.fileList.push(e.list)
				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			//删除图片
			del(_id, _ind) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					process_id: _id,
				}

				axios.post(API_LIST.del_process_txt_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.fileList.splice(_ind, 1)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 
			radioChange(e) {
				console.log(e)
				if (e == 2) {
					this.getFileList()
				}
			},

			//选择问题
			chooseQue_2(_index) {

				if (this.chooseArr2.some(item => {
						return _index == item
					})) {
					this.chooseArr2[_index] = 999
				} else {
					this.chooseArr2[_index] = _index
				}
			},
			//单/多选选择
			chooseOption_2(_ind, _indChild, _select_type) {
				this.status1 = 2
				this.status2 = 2
				if (_select_type == 1) {
					this.show2 = true
					this.getCheckName = {
						ind: _ind,
						indChild: _indChild,
						CheckList: (this.queList2.data[_ind])[_indChild].result
					}
				} else if (_select_type == 2) {
					this.show1 = true
					this.getRadioName = {
						ind: _ind,
						indChild: _indChild,
						radioList: (this.queList2.data[_ind])[_indChild].result
					}
				}

			},
			//新增总结
			addSummary_2() {
				this.addSummaryList2.push({
					id: '',
					title: ''
				})
			},
			//删除自己添加的总结
			yesDel(_index) {

				this.addSummaryList2.splice(_index, 1)
			},
			//选择其他用户评价
			chooseOther_2(_ind) {
				this.show4 = true;
				this.$eventBus.emit('showOtherBox2', {
					ind:_ind,
					ue:2
				})
			},

			//选择问题
			chooseQue_3(_index) {

				if (this.chooseArr3.some(item => {
						return _index == item
					})) {
					this.chooseArr3[_index] = 999
				} else {
					this.chooseArr3[_index] = _index
				}
			},
			//单/多选选择
			chooseOption_3(_ind, _indChild, _select_type) {
				this.status1 = 3
				this.status2 = 3
				if (_select_type == 1) {
					this.show2 = true
					this.getCheckName = {
						ind: _ind,
						indChild: _indChild,
						CheckList: (this.queList3.data[_ind])[_indChild].result
					}
				} else if (_select_type == 2) {
					this.show1 = true
					this.getRadioName = {
						ind: _ind,
						indChild: _indChild,
						radioList: (this.queList3.data[_ind])[_indChild].result
					}
				}

			},
			//新增总结
			addSummary_3() {
				this.addSummaryList3.push({
					id: '',
					title: ''
				})
			},
			//删除自己添加的总结
			yesDel(_index) {

				this.addSummaryList3.splice(_index, 1)
			},
			//选择其他用户评价
			chooseOther_3(_ind) {
				this.show4 = true;
				this.$eventBus.emit('showOtherBox2', {
					ind:_ind,
					ue:3
				})
			},
			close2() {
				console.log(12345)
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
				
			},


			// 
			getFileList() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				axios.post(API_LIST.sel_txt_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.fileList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 保存
			save() {
				const that = this;
				let obj = {
					submit_type: 1,
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
					promo_share: this.experienceList,
					act_expect_cost: this.act_expect_cost, // 活动预计费用
					act_real_cost: this.act_real_cost, // 活动实际核销费用
					acted_real_stock: this.acted_real_stock, // 活动后实际库存量
					meeted_sum: {
						type2: this.radio,
						answer: [],
						answer2: []
					},
					act_evalate: {
						answer: [],
						answer2: []
					},
					next_plan: {
						answer: [],
						answer2: []
					}
				}

				// 总结
				try {
					this.chooseArr.forEach((item, index) => {
						console.log(item)
						let s2 = ''
						if (item != 999) {
							this.ansList[index].ansChild.forEach((itemChild, indexChild) => {
								console.log()
								if (this.ansList[index].ansChild[indexChild].ans.length == 0 &&
									this.queList.data[index][
										indexChild
									].result.length > 0) {
									ElMessage({
										message: '总结请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								obj.meeted_sum.answer.push({
									title: this.queList.data[index][
										indexChild
									].question,
									content: this.ansList[index].ansChild[indexChild]
										.ans,
									words: 'data1',
									id: this.queList.data[index][indexChild]
										.id
								})
								if (this.ansList[index].ansChild[
										indexChild].ans) {
									s2 = s2 + this.queList.data[index][
										indexChild
									].question + this.ansList[index].ansChild[
										indexChild].ans
								} else {
									s2 = s2 + this.queList.data[index][
										indexChild
									].question
								}

							})

						}
						if (item != 999) {
							obj.meeted_sum.answer2.push(s2)
						}

						console.log(33, obj.meeted_sum)



					})
				} catch (e) {
					console.log(111, e)
					if (e.message === 'End Loop') throw e
				}
				if (this.addSummaryList.length > 0) {
					try {
						this.addSummaryList.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							obj.meeted_sum.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}


				}

				// 效果自评
				try {
					this.chooseArr2.forEach((item, index) => {
						console.log(item)
						let s2 = ''
						if (item != 999) {
							this.ansList2[index].ansChild.forEach((itemChild, indexChild) => {
								console.log()
								if (this.ansList2[index].ansChild[indexChild].ans.length == 0 &&
									this.queList2.data[index][
										indexChild
									].result.length > 0) {
									ElMessage({
										message: '总结请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								obj.act_evalate.answer.push({
									title: this.queList2.data[index][
										indexChild
									].question,
									content: this.ansList2[index].ansChild[indexChild]
										.ans,
									words: 'data1',
									id: this.queList2.data[index][indexChild]
										.id
								})
								if (this.ansList2[index].ansChild[
										indexChild].ans) {
									s2 = s2 + this.queList2.data[index][
										indexChild
									].question + this.ansList2[index].ansChild[
										indexChild].ans
								} else {
									s2 = s2 + this.queList2.data[index][
										indexChild
									].question
								}

							})

						}
						if (item != 999) {
							obj.act_evalate.answer2.push(s2)
						}

						console.log(33, obj.act_evalate)



					})
				} catch (e) {
					console.log(111, e)
					if (e.message === 'End Loop') throw e
				}
				if (this.addSummaryList2.length > 0) {
					try {
						this.addSummaryList2.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							obj.act_evalate.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}


				}

				// 下一步计划
				try {
					this.chooseArr3.forEach((item, index) => {
						console.log(item)
						let s2 = ''
						if (item != 999) {
							this.ansList3[index].ansChild.forEach((itemChild, indexChild) => {
								console.log()
								if (this.ansList3[index].ansChild[indexChild].ans.length == 0 &&
									this.queList3.data[index][
										indexChild
									].result.length > 0) {
									ElMessage({
										message: '总结请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								obj.next_plan.answer.push({
									title: this.queList3.data[index][
										indexChild
									].question,
									content: this.ansList3[index].ansChild[indexChild]
										.ans,
									words: 'data1',
									id: this.queList3.data[index][indexChild]
										.id
								})
								if (this.ansList3[index].ansChild[
										indexChild].ans) {
									s2 = s2 + this.queList3.data[index][
										indexChild
									].question + this.ansList3[index].ansChild[
										indexChild].ans
								} else {
									s2 = s2 + this.queList3.data[index][
										indexChild
									].question
								}

							})

						}
						if (item != 999) {
							obj.next_plan.answer2.push(s2)
						}

						console.log(33, obj.next_plan)



					})
				} catch (e) {
					console.log(111, e)
					if (e.message === 'End Loop') throw e
				}
				if (this.addSummaryList3.length > 0) {
					try {
						this.addSummaryList3.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							obj.next_plan.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}


				}



				axios.post(API_LIST.save_data8, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							setTimeout(that.$emit('changeStateSummary', ''), 1000)
						} else {
							this.renList = _d.data.list
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
		},
		// 提交
		submit() {
			const that = this;
			let obj = {
				u_id: localStorage.getItem("u_id"),
				promo_id: this.meetID,
			}

			axios.post(API_LIST.submit_data, obj, {
					headers: {
						'Content-Type': 'application/json'
					} //加上这个
				})
				.then(_d => {
					console.log(_d)
					if (_d.data.status == 200) {
						ElMessage({
							message: _d.data.msg,
							type: 'success',
						})
						setTimeout(function() {
							that.$router.push({
								path: '/Promotion',
								query: {
									id: this.sortID
								}
							});
						}, 500)
					} else {
						ElMessage({
							message: _d.data.msg,
							type: 'warning',
						})

					}
				})
				.catch(function(error) {

				});
		}
	}
</script>

<style scoped lang="scss">
	.notice {

		padding: 15px 0;
		position: relative;
		// box-shadow: 0px 0px 20px #ccc;
		margin: 0 auto;
		width: 100%;
		border-radius: 10px;
		// border: 1px solid #1890FF;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;

		}

		.chooseSpan {
			position: relative;
		}

		.chooseSpan::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			z-index: 100;
			background-color: #000;
			width: 100%;
			height: 100%;
			opacity: 0;
		}

		.noticeBox {
			background-color: #fff;
			box-shadow: 0px 0px 20px #ccc;
			padding: 15px;
			border-radius: 10px;
			width: 95%;
			margin: 15px auto 0;
			box-sizing: border-box;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;


				.title {
					display: inline-block;
					width: 200px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box1 {
				display: flex;
				flex-direction: column;
				// align-items: center;

				ul {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					margin: 20px 0;

					li {
						width: 200px;
						margin: 0 20px 0 0;
						text-align: center;
						overflow: hidden;
					}

					textarea {
						width: 300px;
						box-sizing: border-box;
						padding: 5px;
					}
				}

				.pai {
					li {
						border: 1px solid #ccc;
						height: 70px;
						
						line-height: 70px;
					}

					li:nth-child(1) {
						border: none !important;
					
					}
					
				}
			}

			.box4 {
				display: flex;
				flex-direction: row;
				align-items: center;

				input {
					width: 118px;
					height: 28px;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					padding: 0 0 0 5px;
				}

				.address_content {
					width: 420px;
				}

				.fen {
					display: flex;
					flex-direction: column;

					ul {
						display: flex;
						flex-direction: row;
						align-items: center;

						li {
							margin: 0 20px 0 0;
							width: 100px;
							text-align: center;
						}

						li:nth-child(2) {
							width: 150px;
						}
					}

					.tiao {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin: 10px 0 0 0;

						.kuang {
							width: 100px;
							text-align: center;
							margin: 0 20px 0 0;

						}

						.kuang2 {
							width: 50px;
							text-align: center;
							margin: 0 20px 0 0;

							button {
								background-color: #fff;
								color: #1890FF;
							}

							.del {
								background-color: #fff;
								color: #D50000;
							}
						}

						.kuang:nth-child(2) {
							width: 150px;
						}

						input {
							border: none;
							border: 1px solid #ccc;
							width: 100px;
							text-align: center;
						}
					}
				}

			}

			.up {
				display: flex;
				flex-direction: column;
				padding: 0 0 0 00px;
				box-sizing: border-box;
				margin: 10px 0;

				.title {
					padding: 15px 0;
					border-bottom: 1px solid #ddd;
				}

				.li {
					display: flex;
					flex-direction: column;
					margin: 0 0 15px 0;

					p {
						margin: 15px 0 0 100px;
					}

					img {
						width: 90px;
						height: 90px;
						margin: 0 10px 10px 0;
					}
				}

				.btns {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 15px 0 0 200px;

					.upload-demos {
						width: 110px;
						height: 37px;
						background: #1890ff;
						color: #fff;
						text-align: center;
						line-height: 37px;
						position: relative;

						.input {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 110px;
							height: 37px;
							opacity: 0;
						}
					}

					span {
						color: #000;
						opacity: 0.65;
						font-size: 14px;
						margin: 0 0 0 10px;
					}
				}

				.fileList {
					display: flex;
					flex-direction: column;
					margin: 15px 0px 0 200px;

					.fileListOne {
						margin: 0 0 10px 0;

						img {
							width: 40px;
							height: 40px;
							margin: 0px 10px 0 0px;
						}

						span {
							margin: 0px 10px 0 0px;
						}

						.icon {
							width: 30px;
							height: 30px;
							cursor: pointer;
						}
					}
				}
			}

			.summary {
				width: 761px;
				margin: 0 auto;
				padding: 25px 0 0 0;

				p {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
				}

				.notes {
					color: #E30000;
				}

				.random {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
					position: relative;
					cursor: pointer;
				}

				.random::before {
					position: absolute;
					content: '';
					width: 15px;
					height: 15px;
					left: -25px;
					bottom: 0;
					top: 0;
					margin: auto;
					border: 1px solid #aaa;
					border-radius: 3px;
				}

				.chooseRandom {
					position: relative;
				}

				.chooseRandom::before {
					position: absolute;
					background-color: #1890FF;
				}

				.chooseRandom::after {
					position: absolute;
					left: -19px;
					top: 0;
					bottom: 0;
					margin: auto;
					content: '';
					width: 3px;
					height: 8px;
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					transform: rotate(45deg);
				}

				.summaryWrite {
					display: flex;
					flex-direction: column;
					//align-items:center;
					width: 100%;

					.summaryWriteOne {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;

						.del {
							margin: 15px 0;
							color: #E30000;
							cursor: pointer;
							background-color: #fff;
						}

						.choosePeople {
							color: #1890FF;
							margin: 0 0 0 30px;
							cursor: pointer;
							background-color: #fff;
						}
					}
				}

				.addSummary {
					width: 211px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					text-align: center;
					line-height: 28px;
					margin: 15px auto;
					cursor: pointer;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}

		.save {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 15px 80px 15px 60px;
			background-color: #FFE9E9;
			width: 95%;
			margin: 20px auto;
			box-sizing: border-box;

			button {
				width: 65px;
				height: 32px;
				background: #ff1818;
				border-radius: 2px;
				text-align: center;
				line-height: 32px;
				color: #fff;
			}
		}

		.sub {
			padding: 15px 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background-color: #FFf;
			width: 95%;
			margin: 20px auto;
			box-sizing: border-box;

			button {
				width: 65px;
				height: 32px;
				background: #1890ff;
				border-radius: 2px;
				text-align: center;
				line-height: 32px;
				color: #fff;
			}
		}
	}
</style>
