<template>
	<div class="notice">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<AddHospitalBox @show3='show3($event)'></AddHospitalBox>
		<HospitalBox v-if='showHospitalBox' :meetSort='{id:meetSortId}' :state='state' @close="closeHospitalBox($event)">
		</HospitalBox>
		<ThemeBox :getThemeName="getThemeName"></ThemeBox>
		<TargetBox :getTargetName="getTargetName"></TargetBox>
		<RadioBox v-show='show1' :getRadioName="getRadioName"></RadioBox>
		<CheckBox v-show='show2' :getCheckName="getCheckName"></CheckBox>
		<OtherBox v-show='show4' @close2="close2" :getOtherName="getOtherName"></OtherBox>
		<div class="noticeBox">
			<h3>活动产品</h3>
			<div class="box box1">
				<span class="title">目标产品</span>
				<!-- <el-select v-model="productName.name" filterable @change='changeProduct' placeholder="请选择" size="small">
					<el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.name">
					</el-option>
				</el-select> -->
				<el-select v-model="productName" multiple filterable @blur='changeProduct("productName","reqMsgRef")'
					placeholder="请选择" size="small" ref="reqMsgRef">
					<el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.name">
					</el-option>
				</el-select>
			</div>
		</div>

		<div class="noticeBox">
			<h3>活动时间</h3>
			<div class="box box2">
				<span class="title">会议日期</span>
				<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" :size="size" format="YYYY/MM/DD" value-format="YYYY-MM-DD"
					@change='changeDate' placeholder="选择日期">
				</el-date-picker>
				<!--<el-date-pickerv-model="date"type="daterange"range-separator="→"start-placeholder="开始时间"
					end-placeholder="结束时间":size="size"format="YYYY/MM/DD"value-format="YYYY-MM-DD"
					@change='changeDate'/>-->
			</div>
		</div>

		<div class="noticeBox">
			<h3>活动连锁</h3>
			<div class="box box3" @click="chooseHospital(1,999,3)">
				<span class="title">连锁名称</span>
				<span class="ans" :class="{'border':hospital==''}">{{hospital?hospital:'请选择'}}</span>
			</div>
			<!-- <div class="box box4">
				<span class="title">参与活动门店数量</span>
				<input type="text" v-model="real_shop_num" @blur="storage(3)" placeholder="请填写">
			</div> -->
		</div>

		<div class="noticeBox">
			<h3>活动目标</h3>
			<div class="box box4" @click='chooseTarget'>
				<span class="title">活动目标</span>
				<span class="ans"
					:class="{'border':act_effect.length==0}">{{act_effect.length>0?act_effect.map(item=>item.name).toString():'请选择'}}</span>
			</div>

		</div>
		<div class="noticeBox">
			<h3>任务分解明细</h3>
			<div class="box box4">
				<span class="title">任务分解明细</span>
				<el-radio-group v-model="radio" @change="radioChange">
					<el-radio :label="1">回答问题</el-radio>
					<el-radio :label="2">上传文件</el-radio>
				</el-radio-group>
			</div>
			<div class="box box4" v-if="radio == 1">
				<!-- <span class="title">任务分解明细</span> -->
				<div class="fen">
					<ul>
						<li>序号</li>
						<li>门店</li>
						<li>门店面积(平)</li>
						<li>店员数量(人)</li>
						<li>产品</li>
						<li>任务量(盒)</li>
						<li>实际完成量(盒)</li>
						<li>完成率(%)</li>
					</ul>
					<div class="tiao" v-for="(item,index) in renList" :class="{'red':item.other == 1}">
						<div class='kuang'>
							{{index+1}}
						</div>
						<div class='kuang'>
							<span @click="chooseHospital(1,index,1)">{{item.shop_name}}</span>
						</div>
						<div class='kuang'>
							<input type="number" placeholder="请输入" v-model="item.shop_square">
						</div>
						<div class='kuang'>
							<input type="number" placeholder="请输入" v-model="item.worker_num">
						</div>
						<div class='kuang'>
							<el-select v-model="item.p_name" placeholder="请选择" size='small' width='100%'>
								<el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.name">
								</el-option>
							</el-select>
							<!-- <input type="text"> -->
						</div>
						<div class='kuang'>
							<input type="number" v-model="item.task_num" placeholder="请输入" @input="changes(index)">
						</div>

						<div class='kuang'>
							<input type="number" v-model='item.complete_num' placeholder="请输入" @input="changes(index)">
						</div>
						<div class='kuang'>
							<span>{{item.complete_mix}}</span>
						</div>
						<div class='kuang2'>
							<button @click="add">增加</button>
						</div>
						<div class='kuang2'>
							<button class="del" @click="delR(index)">删除</button>
						</div>
					</div>


				</div>
			</div>
			<div v-if="radio == 2">
				<div class="up">
					<div class="downLoad">
						<a href="https://xcx.uzhikang.com/wx/任务分解-表头.xlsx">点击下载表头</a>
					</div>	
					<div class="btns">
						
						<button class="upload-demos">上传文件
							<el-upload :action="files" :multiple='false' :data='fileData' :on-success='upSuccess'
								class="input" accept='.xlsx,.xls'>
								<el-button type="primary">上传文件</el-button>
							</el-upload>
						</button><span>支持xlsx格式，每个文件不大于10M</span>
					</div>
					<!-- <div class="fileList">
						<div v-for="(item,index) in fileList" class="fileListOne">
							<img src="../../assets/fileImg.png" alt="">
							<span>{{item.url2}}</span>
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del(item.id,index)'>
						</div>
					</div> -->
				</div>
				<div class="fen" v-show="renList2.length>0">
					<ul>
						<li>序号</li>
						<li>门店</li>
						<li>门店面积(平)</li>
						<li>店员数量(人)</li>
						<li>产品</li>
						<li>任务量(盒)</li>
						<li>实际完成量(盒)</li>
						<li>完成率(%)</li>
					</ul>
					<div class="tiao" v-for="(item,index) in renList2" :class="{'red':item.other == 1}">
						<div class='kuang'>
							{{index+1}}
						</div>
						<div class='kuang'>
							<span>{{item.shop_name}}</span>
						</div>
						<div class='kuang'>
							<span>{{item.shop_square}}</span>
						</div>
						<div class='kuang'>
							<span>{{item.worker_num}}</span>
						</div>
						<div class='kuang'>
							<span>{{item.p_name}}</span>
						</div>
						<div class='kuang'>
							<span>{{item.task_num}}</span>
						</div>

						<div class='kuang'>
							<span>{{item.complete_num}}</span>
						</div>
						<div class='kuang'>
							<span>{{item.complete_mix}}</span>
						</div>

					</div>


				</div>
			</div>


		</div>
		<div class="noticeBox">
			<h3>活动推广具体实操方案</h3>
			<div class="box box4">
				<span class="title">活动推广具体实操方案</span>
				<el-radio-group v-model="radio2" @change="radioChange2">
					<el-radio :label="1">回答问题</el-radio>
					<el-radio :label="2">上传文件</el-radio>
				</el-radio-group>
			</div>
			<div class="box box4" v-if="radio2 == 1">
				<div class="summary">
					<p class="notes">*注: 以下问题根据实际情况选择作答，或者点击“+会后跟进及计划” 自行添加（审核指标：不能一直只用数据库的，要有一定占比自行增加）</p>

					<div v-for='(item,index) in queList.data' class="random"
						:class="{'chooseRandom':chooseArr[index]==index}" @click="chooseQue(index)">
						<div>{{index+1}}.
							<span v-for="(itemChild,indexChild) in item"
								:class="{'chooseSpan':chooseArr[index] != index}">

								<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==1">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
								<span>{{itemChild.question}}</span>
								<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
									@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
									v-if="itemChild.position==2">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
							</span>
						</div>

					</div>

					<div class="summaryWrite">
						<div v-for="(item,index) in addSummaryList" class="summaryWriteOne">
							<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.title">
							</el-input>
							<div>
								<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel(index)'>
									<template #reference>
										<button slot="reference" class="del">删除</button>
									</template>
								</el-popconfirm>
								<button class="choosePeople" @click='chooseOther(index)'>选择其他用户计划</button>
							</div>

						</div>
					</div>
					<div class="addSummary" @click="addSummary">
						+会后跟进及计划
					</div>

				</div>
			</div>
			<div v-if="radio2 == 2">
				<div class="up">
					<div class="btns">
						<button class="upload-demos">上传文件
							<el-upload :action="files2" :multiple='false' :data='fileData2' :on-success='upSuccess2'
								:before-upload='onchangemd' class="input">
								<el-button type="primary">上传文件</el-button>
							</el-upload>
						</button><span>支持ppt/pdf/jpg/jpeg/png格式，每个文件不大于10M</span>
					</div>
					<div class="fileList">
						<div v-for="(item,index) in fileList" class="fileListOne">
							<img src="../../assets/fileImg.png" alt="">
							<span>{{item.url2}}</span>
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del(item.id,index)'>
						</div>
					</div>
				</div>
			</div>


		</div>
		<div class="save">
			<span>当前填写页</span>
			<button @click="save">保存</button>
		</div>
		<div class="sub" v-if="status_state.act_plan == 1 && status_state.act_sum == 1 && status_state.act_file == 1&&status_state.state != 1" >
			<button @click="submit">提交</button>
		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import AddHospitalBox from "@/components/Promotion/AddHospitalBox.vue";
	import HospitalBox from "@/components/Promotion/HospitalBox.vue";
	import KeBox from "@/components/KeBox.vue";
	import ThemeBox from "@/components/ThemeBox.vue";
	import TargetBox from "@/components/Promotion/TargetBox.vue";
	import RadioBox from "@/components/RadioBox.vue";
	import CheckBox from "@/components/CheckBox.vue";
	import OtherBox from "@/components/Promotion/OtherBox.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {

		regionData

	} from "element-china-area-data";
	export default {
		name: "Plan",
		data() {
			return {
				name: 'Plan',
				showMask: false,
				complete: false, //线上会议通知是否填写完整
				productName: [], //产品
				hospital: '', //医院名
				department: [], //科室列表
				date: '', //日期
				productList: '', //产品列表
				size: 'small',
				theme: [], //会议主题
				act_effect: [], //活动目标
				real_shop_num: '', //连锁门店数量
				speaker: '', //主讲者
				pcTextArr: regionData,
				selectedOptions: [],
				address: '', // 详细地址
				showHospitalBox: false, // 显示医院的列表
				radio: 1,
				radio2: 1,
				index: 999, //查看是选中了哪条数据
				state: '',
				renList: [{
					shop_name: '', // 门店名称
					shop_square: '', // 门店面积
					p_name: '',
					worker_num: '', // 员工数量
					task_num: '', // 任务量
					complete_num: '', // 完成量
					complete_mix: '', // 完成率
					other: '',
				}],
				renList2: [], // 上传的任务分解明细
				files: API_LIST.importExecl, // 上传任务分解明细文件
				fileData: {

				},
				files2: API_LIST.uploadNew_8, // 上传任务分解明细文件
				fileData2: {},
				fileList:[],
				show1: false,
				show2: false,
				show4: false,
				queList: [],
				chooseArr: [],
				ansList: [],
				addSummaryList: [], //增加的效果评价输入框列表
				meetSortId:3,
				keyID:7
			}
		},
		components: {
			HospitalBox,
			AddHospitalBox,
			KeBox,
			ThemeBox,
			TargetBox,
			RadioBox,
			CheckBox,
			OtherBox
		},
		props:['status_state'],
		
		created() {
			//单/多选完后传回来的值
			this.$eventBus.on('CheckChooseCu', data => {
				console.log(1234567)
				console.log(data)
				console.log(this.ansList)
				this.show1 = false
				this.show2 = false
				this.show4 = false
				this.ansList[data.index.ind].ansChild[data.index.indChild].ans = data.choose
			})
			//单选传回来的值
			this.$eventBus.on('CheckChooseCu2', data => {
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
				this.chooseApprove = data.choose;
			})
			//
			this.$eventBus.on('otherChoose', data => {
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
				console.log(this.addSummaryList)
				this.addSummaryList[data.index] = {
					id: data.choose[0].id,
					title: data.choose[0].question
				}
				console.log(this.addSummaryList)
			})
			//关闭
			this.$eventBus.on('close', data => {
				console.log(222)
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
			})
			//医院
			this.$eventBus.off('hospitalChoose')
			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.sortID = this.$route.query.sortID
			this.fileData = {
				u_id: localStorage.getItem("u_id"),
				promo_id: this.meetID
			}
			this.fileData2 = {
				u_id: localStorage.getItem("u_id"),
				promo_id: this.meetID,
				img_type: 3,
				type: 2,
				img_type: 1,
				pub_key: 'plan',
				file_type: 4,
				submit_type: 2
			}
			if(this.status_state.state == 1){
				this.showMask = true
			}else{
				console.log(this.status_state)
			}
			
			// this.show3 = true
			
			this.getListInfo();
			this.getWriteInfo();
			
		},
		watch: {
			
			renList2(newData, oldData) {
				this.renList2.forEach((item) => {
					console.log(item)
					item.complete_mix = (Number(item.complete_num) / Number(item.task_num) * 100).toFixed(2)
				})
			},
			

		},
		computed: {

		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message:'该区域禁止更改',
					type:'warning',
				})
			},
			//获取产品列表
			getListInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					agreement_id: this.xyID,
					p_type: 1
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.tg_product_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.productList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取已经输入的值
			getWriteInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.promo_page1, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						console.log(11111, _d.data.list)
						if (_d.data.status == 200) {
							this.productName = _d.data.list.promo.product_arr
							if(this.productName.length > 0){
								this.getListInfos()
							}
							this.date = [_d.data.list.promo.start_time,_d.data.list.promo.end_time]
							this.hospital = _d.data.list.promo.chain_name
							this.real_shop_num = _d.data.list.promo.real_shop_num
							this.act_effect = _d.data.list.promo.act_effect
							this.radio = _d.data.list.promo.task_data_type
							if(this.radio == 1){
								this.renList = _d.data.list.promo.task_data
							}else if(this.radio == 2){
								this.renList2 = _d.data.list.promo.task_data
							}
							this.radio2 = _d.data.list.promo.se4_type2
							console.log(11111,this.addSummaryList)
							_d.data.list.promo.se4.forEach(item =>{
								this.addSummaryList.push(item)
							})
							console.log(22222,this.addSummaryList)
							// this.queList.data = _d.data.list.promo.se_data
							
							// this.queList.promo.se_data.forEach((item, index) => {
							// 	console.log(index)
							// 	this.ansList.push({
							// 		ans: '',
							// 		ansChild: []
							// 	})
							// 	if (item[0].checked == 1) {
							// 		this.chooseArr.push(index)
							// 	} else {
							// 		this.chooseArr.push(999)
							// 	}
							
							// 	item.forEach((itemChild, indexChild) => {
							// 		// if (itemChild.result.length != 0) {
							// 			this.ansList[index].ansChild.push({
							// 				ans: itemChild.answer
							// 			})
							// 		// }
							// 	})
							// })
							
							console.log(123,this.ansList)
							
							// if(){
								
							// }
							
							
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},
			//选择产品
			changeProduct(flag, ref) {
				const that = this;
				console.log(this.$refs)
				this.$refs[ref].$refs.input.blur = () => {
					// 这里执行失焦的代码
					if (this[flag].length > 0) {
						that.arr = []
						for (let i = 0; i < that[flag].length; i++) {
							let obj = that.productList.findIndex((item) => {
								return item.name === that[flag][i]
							})
							console.log(obj)
							that.arr.push(that.productList[obj].id.toString())
						}
						console.log(that.arr)
						this.getListInfos()
						this.storage(1)
					} else {
						ElMessage({
							message: '请选择目标产品',
							type: 'warning',
						})
					}

				};

			},
			// 选择沙龙类别
			changeSort(e) {
				console.log(e)
				console.log(this.sortList)
				let ind = this.sortList.findIndex(item => {
					return e == item.name
				})
				console.log(this.sortList)
				this.meetSort = this.sortList[ind];
				console.log(this.meetSort)
				console.log(this.sortList)
				console.log(ind)
				this.storage(13)
			},
			//储存实时数据
			storage(_state) {
				console.log(this.complete)
				if (this.complete) {
					ElMessage({
						message: '该区域禁止更改',
						type: 'warning',
					})
					return
				}
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID
				}
				if(_state == 3 && !this.isInteger(this.real_shop_num)){
					ElMessage({
						message: '门店数量为正整数',
						type: 'warning',
					})
					return
				}
				switch (_state) {
					case 1:
						obj.product_id = this.arr
						break
					case 2:
						obj.start_time = this.date[0]
						obj.end_time = this.date[1]
						break
					case 3:
						obj.real_shop_num = this.real_shop_num
						break
					case 4:
						obj.chain_name = this.hospital
						break
					case 5:
						obj.act_effect = this.act_effect
						break
				}


				let a = qs.stringify(obj)

				axios.post(API_LIST.save_pc_product8, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							if (_state == 1) {
								this.getListInfos()
							}
							if (_d.data.list.meet_notice == 1) {
								console.log(_d.data.list.meet_notice)
								this.complete = true
							} else {
								this.complete = false
							}

							//location.reload()
						} else {
							if (_state == 6) {
								this.date = '' //日期
							}
							if (_state == 8) {
								this.timeSolt = ''
							}
							if (_state == 7) {
								this.speaker = '' //主讲者
							}
							if (_state == 3) {
								this.department = [] //科室列表
							}
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})


						}
					})
					.catch(function(error) {

					});
			},
			//选择日期
			changeDate(_date) {
				console.log(this.date)
				this.storage(2)
			},
			//选择医院
			chooseHospital(_state, _id,_sort) {
				this.meetSortId = _sort;
				this.index = _id;
				this.state = _state;
				this.showHospitalBox = true;
				// this.$eventBus.emit('show3', {
				// 	show: true
				// })
			},
			//选择科室
			chooseKe() {
				if (!this.hospital) {
					ElMessage({
						message: '请选择医院',
						type: 'warning',
					})
					return
				}
				if (!this.productName.id) {
					ElMessage({
						message: '请选择目标产品',
						type: 'warning',
					})
					return
				}
				this.$eventBus.emit('showKeBox', {
					show: true,
					meetID: this.meetID
				})
			},
			//获取科室名称
			getKeName(name) {

				this.department = name
				this.storage(3)
			},
			//选择活动目标
			chooseTheme() {
				if (this.arr.length == 0) {
					ElMessage({
						message: '请选择目标产品',
						type: 'warning',
					})
					return
				}
				this.$eventBus.emit('showThemeBox', {
					show: true,
					meetID: this.meetID,
					sortID: this.sortID
				})
			},
			//获取主题名称
			getThemeName(name) {

				this.theme = name
				this.storage(4)
			},
			//活动目标
			chooseTarget() {
				if (this.productName.length == 0) {
					ElMessage({
						message: '请选择目标产品',
						type: 'warning',
					})
					return
				}
				this.$eventBus.emit('showTargetBox2', {
					show: true,
					meetID: this.meetID
				})
			},
			//获取目标名称
			getTargetName(name) {
				console.log(name)
				this.act_effect = name
				this.storage(5)
			},
			//输入主讲者时判断是否填写了会议日期
			determine(e) {
				if (!this.date) {
					ElMessage({
						message: '请先选择会议日期',
						type: 'warning',
					})
					e.srcElement.blur();
				}
			},
			// 输入详细地址时判定是否选择了会议地点
			determine2(e) {
				if (this.selectedOptions.length == 0) {
					ElMessage({
						message: '请先选择会议地点',
						type: 'warning',
					})
					e.srcElement.blur();
				}
			},
			// 关闭显示医院列表
			closeHospitalBox(e) {
				this.showHospitalBox = e.show
				if (e.state == 3 && this.state == 1) {
					this.hospital = e.hospitalName
					this.storage(4)
				} else if (e.state == 1) {
					console.log(123)
					this.renList[this.index].shop_name = e.hospitalName
				}
			},
			show3(e) {
				console.log(e)
				if (this.meetSort.name == '') {
					ElMessage({
						message: '请先选择参会人员类型',
						type: 'warning',
					})
					return
				}
				this.showHospitalBox = e.show
			},
			// 任务分解明细选择上传还是自填
			radioChange(e) {
				console.log(e)
				if (e == 2) {
					this.getFileList()
				}
			},
			// 
			getFileList() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				axios.post(API_LIST.sel_txt_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.fileList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 任务分解明细选择上传还是自填
			radioChange2(e) {
				console.log(e)
				if (e == 2) {
					this.getFileList2()
				}
			},
			// 
			getFileList2() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				axios.post(API_LIST.sel_txt_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.fileList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 计算完成率
			changes(_ind) {
				console.log(this.renList[_ind].complete_num)
				console.log(this.renList[_ind].task_num)
				if (this.renList[_ind].complete_num != '' && this.renList[_ind].task_num != '') {
					this.renList[_ind].complete_mix = (Number(this.renList[_ind].complete_num) / Number(this.renList[_ind]
						.task_num) * 100).toFixed(2)
				}

			},
			// 增加
			add() {
				this.renList.push({
					shop_name: '', // 门店名称
					shop_square: '', // 门店面积
					p_name: '',
					worker_num: '', // 员工数量
					task_num: '', // 任务量
					complete_num: '', // 完成量
					complete_mix: '', // 完成率
					other: '',
				})
			},
			// 删除
			delR(_index) {
				this.renList.splice(_index, 1)
			},
			// 上传后返回的数据
			upSuccess(e) {
				console.log(e)
				this.renList2 = [];
				if (e.status == 200) {
					console.log(e.list)
					for (let i in e.list) {
						this.renList2.push(e.list[i])
					}
					console.log(this.renList2[2])
				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			// 保存
			save() {
				const that = this;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
					submit_type: 1,
					act_task:{
						
					},
					act_plan: {
						type2: this.radio,
						answer: [],
						answer2: []
					}
				}

				
				if (this.radio == 1) {
					obj.act_task = {
						type:this.radio,
						task:[]
					}
					
					for (let i = 0; i < this.renList.length; i++) {
						if(!this.isInteger(this.renList[i].worker_num)){
							console.log(this.renList[i].worker_num)
							ElMessage({
								message: '店员数量为正整数',
								type: 'warning',
							})
							return false
						}
					}
					obj.act_task.task = this.renList
					
				} else if (this.radio == 2) {
					obj.act_task = {
						type:this.radio,
						task:[]
					}
					for (let j = 0; j < this.renList2.length; j++) {
						if (this.renList2[j].other == 1) {
							ElMessage({
								message: '错误数据，请更改',
								type: 'warning',
							})
							return false
						}
						if(!this.isInteger(this.renList2[j].worker_num)){
							console.log(this.renList2[j].worker_num)
							ElMessage({
								message: '店员数量为正整数',
								type: 'warning',
							})
							return false
						}
						if(!this.isInteger(this.renList2[j].task_num)){
							console.log(this.renList2[j].task_num)
							ElMessage({
								message: '任务量为正整数',
								type: 'warning',
							})
							return false
						}
						if(!this.isInteger(this.renList2[j].complete_num)){
							console.log(this.renList2[j].complete_num)
							ElMessage({
								message: '实际完成量为正整数',
								type: 'warning',
							})
							return false
						}
					}
					obj.act_task.task = this.renList2
				}

				console.log(this.chooseArr)

				try {
					this.chooseArr.forEach((item, index) => {
						console.log(item)
						let s2 = ''
						if (item != 999) {
							this.ansList[index].ansChild.forEach((itemChild, indexChild) => {
								console.log(this.ansList)
								if (this.ansList[index].ansChild[indexChild].ans.length == 0 &&
									this.queList.data[index][
										indexChild
									].result.length > 0) {
									ElMessage({
										message: '活动实操具体推广方案请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								obj.act_plan.answer.push({
									title: this.queList.data[index][
										indexChild
									].question,
									content: this.ansList[index].ansChild[indexChild]
										.ans,
									words: 'plan',
									id: this.queList.data[index][indexChild]
										.id
								})
								console.log('=======',this.queList.data[index][indexChild].position)
								if (this.ansList[index].ansChild[
										indexChild].ans && this.queList.data[index][indexChild].position == 2) {
									console.log('-------')
									s2 = s2 + this.queList.data[index][
										indexChild
									].question + this.ansList[index].ansChild[
										indexChild].ans
								}else if(this.ansList[index].ansChild[
										indexChild].ans && this.queList.data[index][indexChild].position == 1){
									console.log('++++++++')
									s2 = s2 + this.ansList[index].ansChild[
										indexChild].ans +this.queList.data[index][
										indexChild
									].question
								} else {
									s2 = s2 + this.queList.data[index][
										indexChild
									].question
								}
								console.log(s2)

							})

						}
						if (item != 999) {
							obj.act_plan.answer2.push(s2)
						}

						// console.log(33, obj.act_plan)



					})
				} catch (e) {
					console.log(111, e)
					if (e.message === 'End Loop') throw e
				}
				if (this.addSummaryList.length > 0) {
					try {
						this.addSummaryList.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							obj.act_plan.answer.push({
								title: item.title,
								content: '',
								words: 'plan2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}


				}




				axios.post(API_LIST.save_pc_product8, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							setTimeout(that.$emit('changeStatePlan',''),1000)
						} else {
							// this.renList = _d.data.list
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},


			//获取问题和选项
			getListInfos() {

				this.chooseArr = []
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.take_plan_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							this.queList = _d.data.list
							console.log('=====', this.queList)
							_d.data.list.data.forEach((item, index) => {
								this.ansList.push({
									ans: '',
									ansChild: []
								})
								if (item[0].checked == 1) {
									this.chooseArr.push(index)
								} else {
									this.chooseArr.push(999)
								}

								item.forEach((itemChild, indexChild) => {
									// if (itemChild.result.length != 0) {
									this.ansList[index].ansChild.push({
										ans: itemChild.answer
									})
									// }
								})
							})
							// this.addSummaryList = _d.data.list.data2.length == 0 ? [] : _d.data.list.data2
							console.log('dhjak', this.ansList)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//选择问题
			chooseQue(_index) {

				if (this.chooseArr.some(item => {
						return _index == item
					})) {
					this.chooseArr[_index] = 999
				} else {
					this.chooseArr[_index] = _index
				}
			},
			//单选选择
			chooseOption2() {
				this.show1 = true
				this.$eventBus.emit('showRadioBox', {
					ind: 9999,
					indChild: '',
					radioList: this.queList.reco,
					keyID:this.keyID
				})
			},
			//单/多选选择
			chooseOption(_ind, _indChild, _select_type) {
				if (_select_type == 1) {
					this.show2 = true
					this.$eventBus.emit('showCheckBox', {
						ind: _ind,
						indChild: _indChild,
						CheckList: (this.queList.data[_ind])[_indChild].result,
						keyID:this.keyID
					})
				} else if (_select_type == 2) {
					this.show1 = true
					this.$eventBus.emit('showRadioBox', {
						ind: _ind,
						indChild: _indChild,
						radioList: (this.queList.data[_ind])[_indChild].result,
						keyID:this.keyID
					})
				}

			},
			close2() {
				console.log(12345)
				this.show1 = false;
				this.show2 = false;
				this.show4 = false;
				
			},
			//新增总结
			addSummary() {
				this.addSummaryList.push({
					id: '',
					title: ''
				})
			},
			//删除自己添加的总结
			yesDel(_index) {

				this.addSummaryList.splice(_index, 1)
			},
			//选择其他用户评价
			chooseOther(_ind) {
				this.show4 = true;
				console.log(1111)
				// console.log(this.show3)
				this.$eventBus.emit('showOtherBox2', {
					ind:_ind,
					ue:4
				})
			},
			//上传文件
			upSuccess2(e) {
				if (e.status == 200) {
					ElMessage({
						message: e.msg,
						type: 'success',
					})

					this.fileList.push(e.list)
				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			//删除图片
			del(_id, _ind) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					process_id: _id,
				}

				axios.post(API_LIST.del_process_txt_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.fileList.splice(_ind, 1)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 
			radioChange(e) {
				console.log(e)
				if (e == 2) {
					this.getFileList()
				}
			},
			// 
			getFileList() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				axios.post(API_LIST.sel_txt_7, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.fileList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 提交
			submit(){
				const that = this;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				
				axios.post(API_LIST.submit_data, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							setTimeout(function(){
								that.$router.push({
									path: '/Promotion',
									query: {
										id:this.sortID
									}
								});
							}, 500)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
				
						}
					})
					.catch(function(error) {
				
					});
			},
			// 正整数判定
			isInteger(obj) {
				if(obj%1 === 0 && obj>0){
					return true
				}else{
					return false
				}
			 
			}
			
		}
	}
</script>

<style scoped lang="scss">
	.notice {

		padding: 15px 0;
		position: relative;
		// box-shadow: 0px 0px 20px #ccc;
		margin: 0 auto;
		width: 100%;
		border-radius: 10px;
		// border: 1px solid #1890FF;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;

		}

		.chooseSpan {
			position: relative;
		}

		.chooseSpan::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			z-index: 100;
			background-color: #000;
			width: 100%;
			height: 100%;
			opacity: 0;
		}

		.noticeBox {
			background-color: #fff;
			box-shadow: 0px 0px 20px #ccc;
			padding: 15px;
			border-radius: 10px;
			width: 95%;
			margin: 15px auto 0;
			box-sizing: border-box;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;


				.title {
					display: inline-block;
					width: 200px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box1 {
				align-items: center;
			}

			.box4 {
				display: flex;
				flex-direction: row;
				align-items: center;

				input {
					width: 118px;
					height: 28px;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					padding: 0 0 0 5px;
				}

				.address_content {
					width: 420px;
				}

				.fen {
					display: flex;
					flex-direction: column;

					ul {
						display: flex;
						flex-direction: row;
						align-items: center;

						li {
							margin: 0 20px 0 0;
							width: 100px;
							text-align: center;
						}

						li:nth-child(2) {
							width: 150px;
						}

						li:nth-child(5) {
							width: 150px;
						}
					}

					.red {
						background-color: #f33;

					}

					.tiao {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin: 10px 0 0 0;
						padding: 5px 0;

						.kuang {
							width: 100px;
							text-align: center;
							margin: 0 20px 0 0;
							display: flex;
							flex-direction: column;
							align-items: center;
							
							span {
								display: inline-block;
								min-height: 27px;
								border: 1px solid #ccc;
								width: 100px;
								text-align: center;
								line-height: 27px;
								min-height: 30rpx;

							}
						}

						.kuang2 {
							width: 50px;
							text-align: center;
							margin: 0 20px 0 0;

							button {
								background-color: #fff;
								color: #1890FF;
								cursor: pointer;
							}

							.del {
								background-color: #fff;
								color: #D50000;
							}
						}

						.kuang:nth-child(2) {
							width: 150px;
							overflow: hidden;
						}

						.kuang:nth-child(5) {
							width: 150px;
						}

						input {
							border: none;
							border: 1px solid #ccc;
							width: 100px;
							text-align: center;
						}

					}
				}

			}

			.up {
				display: flex;
				flex-direction: column;
				padding: 0 0 0 00px;
				box-sizing: border-box;
				margin: 10px 0;
				
				.title {
					padding: 15px 0;
					border-bottom: 1px solid #ddd;
				}

				.li {
					display: flex;
					flex-direction: column;
					margin: 0 0 15px 0;

					p {
						margin: 15px 0 0 100px;
					}

					img {
						width: 90px;
						height: 90px;
						margin: 0 10px 10px 0;
					}
				}
				.downLoad{
					margin: 15px 0 0 200px;
					a{
						color: #1890FF;
						display: inline-block;
						border-bottom: 1px solid #1890FF;
					}
				}
				.btns {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 15px 0 0 200px;

					.upload-demos {
						width: 110px;
						height: 37px;
						background: #1890ff;
						color: #fff;
						text-align: center;
						line-height: 37px;
						position: relative;

						.input {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 110px;
							height: 37px;
							opacity: 0;
						}
					}

					span {
						color: #000;
						opacity: 0.65;
						font-size: 14px;
						margin: 0 0 0 10px;
					}
				}

				.fileList {
					display: flex;
					flex-direction: column;
					margin: 15px 0px 0 200px;

					.fileListOne {
						margin: 0 0 10px 0;

						img {
							width: 40px;
							height: 40px;
							margin: 0px 10px 0 0px;
						}

						span {
							margin: 0px 10px 0 0px;
						}

						.icon {
							width: 30px;
							height: 30px;
							cursor: pointer;
						}
					}
				}

			}

			.fen {
				display: flex;
				flex-direction: column;

				ul {
					display: flex;
					flex-direction: row;
					align-items: center;

					li {
						margin: 0 20px 0 0;
						width: 100px;
						text-align: center;
					}

					li:nth-child(2) {
						width: 150px;
					}

					li:nth-child(5) {
						width: 150px;
					}
				}

				.red {
					background-color: #f33;

				}

				.tiao {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 10px 0 0 0;
					padding: 5px 0;

					.kuang {
						width: 100px;
						text-align: center;
						margin: 0 20px 0 0;
						display: flex;
						flex-direction: column;
						align-items: center;

						span {
							display: inline-block;
							min-height: 27px;
							border: 1px solid #ccc;
							width: 100px;
							text-align: center;
							line-height: 27px;
							overflow: hidden;
							min-height: 30rpx;
						}
					}

					.kuang2 {
						width: 50px;
						text-align: center;
						margin: 0 20px 0 0;

						button {
							background-color: #fff;
							color: #1890FF;
							cursor: pointer;
						}

						.del {
							background-color: #fff;
							color: #D50000;
						}
					}

					.kuang:nth-child(2) {
						width: 150px;
						overflow: hidden;
					}

					.kuang:nth-child(5) {
						width: 150px;
					}

					input {
						border: none;
						border: 1px solid #ccc;
						width: 100px;
						text-align: center;
					}

				}
			}

			.summary {
				width: 761px;
				margin: 0 auto;
				padding: 25px 0 0 0;

				p {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
				}

				.notes {
					color: #E30000;
				}

				.random {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
					position: relative;
					cursor: pointer;
				}

				.random::before {
					position: absolute;
					content: '';
					width: 15px;
					height: 15px;
					left: -25px;
					bottom: 0;
					top: 0;
					margin: auto;
					border: 1px solid #aaa;
					border-radius: 3px;
				}

				.chooseRandom {
					position: relative;
				}

				.chooseRandom::before {
					position: absolute;
					background-color: #1890FF;
				}

				.chooseRandom::after {
					position: absolute;
					left: -19px;
					top: 0;
					bottom: 0;
					margin: auto;
					content: '';
					width: 3px;
					height: 8px;
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					transform: rotate(45deg);
				}

				.summaryWrite {
					display: flex;
					flex-direction: column;
					//align-items:center;
					width: 100%;

					.summaryWriteOne {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;

						.del {
							margin: 15px 0;
							color: #E30000;
							cursor: pointer;
							background-color: #fff;
						}

						.choosePeople {
							color: #1890FF;
							margin: 0 0 0 30px;
							cursor: pointer;
							background-color: #fff;
						}
					}
				}

				.addSummary {
					width: 211px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					text-align: center;
					line-height: 28px;
					margin: 15px auto;
					cursor: pointer;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}

		.save {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 15px 80px 15px 60px;
			background-color: #FFE9E9;
			width: 95%;
			margin: 20px auto;
			box-sizing: border-box;

			button {
				width: 65px;
				height: 32px;
				background: #ff1818;
				border-radius: 2px;
				text-align: center;
				line-height: 32px;
				color: #fff;
			}
		}

		.sub {
			padding: 15px 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background-color: #FFf;
			width: 95%;
			margin: 20px auto;
			box-sizing: border-box;

			button {
				width: 65px;
				height: 32px;
				background: #1890ff;
				border-radius: 2px;
				text-align: center;
				line-height: 32px;
				color: #fff;
			}
		}
	}
</style>
