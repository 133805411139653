<template>
	<div v-if="show">
		<Mask></Mask>
		<div class="box">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">会议模式</div>
			<ul>
				<li @click="choose(index)" v-for="(item,index) in menuList" :class="{'choose':index==chooseNum}">
					{{item.name}}
				</li>
			</ul>
			<button @click="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";
	export default {
		name: "ModeBox",
		data() {
			return {
				show: false,
				menuList: [{
					name: '线上培训'
				}, {
					name: '线下培训'
				}, {
					name: '线上线下结合培训'
				}],
				chooseNum: 0, // 默认选中

			}
		},
		components: {
			Mask
		},
		created() {
			// this.$eventBus.off('show1')
			this.$eventBus.on('show1', data => {
				console.log(data)
				this.show = data.data
				this.sortID = data.id
				switch(this.sortID){
					case 1:
						this.menuList = [{
							id:1,
							name: '线上培训'
						}, {
							id:2,
							name: '线下培训'
						}, {
							id:3,
							name: '线上线下结合培训'
						}];
						break;
					case 2:
						this.menuList = [{
							id:1,
							name: '线上培训'
						}, {
							id:2,
							name: '线下培训'
						}, {
							id:3,
							name: '线上线下结合培训'
						}];
						break;
					case 3:
						this.menuList = [{
							id:1,
							name: '线上培训'
						}, {
							id:2,
							name: '线下培训'
						}, {
							id:3,
							name: '线上线下结合培训'
						}];
						break;
					case 4:
						this.menuList = [{
							id:2,
							name: '线下培训'
						}];
						break;
					case 5:
						this.menuList = [{
							id:2,
							name: '线下培训'
						}];
						break;
					case 6:
						this.menuList = [{
							id:1,
							name: '线上培训'
						}, {
							id:2,
							name: '线下培训'
						}, {
							id:3,
							name: '线上线下结合培训'
						}];
						break;	
					case 7:
						this.menuList = [{
							id:1,
							name: '线上培训'
						}, {
							id:2,
							name: '线下培训'
						}, {
							id:3,
							name: '线上线下结合培训'
						}];
						break;
					case 8:
						this.menuList = [{
							id:1,
							name: '线上培训'
						}, {
							id:2,
							name: '线下培训'
						}, {
							id:3,
							name: '线上线下结合培训'
						}];
						break;
				}
				
			})

		},
		beforeDestroy() {

			this.$eventBus.off('show1')
		},
		methods: {
			// 选中
			choose(_ind) {
				this.chooseNum = _ind
			},
			// 关闭
			close() {
				this.show = false
			},
			// 确定
			submit() {
				this.$router.push({
					path: '/ListHui',
					query: {
						id: this.sortID,
						type: this.menuList[this.chooseNum].id
					}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 528px;
		height: 274px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		ul {
			width: 90%;
			min-height: 130px;
			margin: 0 auto;

			li {
				width: 100%;
				height: 32px;
				line-height: 32px;
				border-radius: 2px;

				margin-bottom: 10px;
				text-align: center;
				cursor: pointer;
			}

			.choose {
				background-color: #1890ff;
				color: #fff;
			}
		}

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
