<template>
	<div class="mask"></div>
</template>

<script>
</script>

<style>
	.mask{
		position:fixed;
		left:0;
		top:0;
		height:100%;
		width:100%;
		z-index:100;
		background-color:#000;
		opacity:0.3;
	}
</style>