<template>
	<div class="sheet">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<!--<AddHospitalBox></AddHospitalBox>-->
		<RadioBox v-show='show1' :getRadioName="getRadioName"></RadioBox>
		<CheckBox v-show='show2' :getCheckName="getCheckName"></CheckBox>
		<OtherBox v-show='show3' :getOtherName="getOtherName"></OtherBox>
		<!--<ThemeBox:getThemeName="getThemeName"></ThemeBox>
		<TargetBox:getTargetName="getTargetName"></TargetBox>-->
		<div class="sheetBox">
			<div class="box box1">
				<span class="title">会后总结会议效果及评价</span>
				<el-radio-group v-model="radio" @change="radioChange">
					<el-radio :label="1">回答问题</el-radio>
					<el-radio :label="2">上传文件</el-radio>
				</el-radio-group>
			</div>
			<div v-show="radio==1" class="summary">

				<p>1. {{queList.msg1}}</p>
				<p>2. {{queList.msg2.title}}<span
						style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
						@click="chooseOption2()">{{chooseApprove.length>0?chooseApprove.toString():'点击选择'}}</span></p>
				<p class="notes">*注：以下问题根据实际情况选择作答，或者点击“+会后总结会议效果及评价” 自行添加（这项目审核指标自行填写部分不低于30%）</p>

				<div v-for='(item,index) in queList.data' class="random"
					:class="{'chooseRandom':chooseArr[index]==index}" @click="chooseQue(index)">
					<div>
						<!--<el-radio:label="1"></el-radio>-->
						<span v-for="(itemChild,indexChild) in item" :class="{'chooseSpan':chooseArr[index] != index}">

							<span style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
								@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
								v-if="itemChild.position==1">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span><span>{{itemChild.question}}</span><span
								style="color:deepskyblue;cursor:pointer;border-bottom: 1px solid #000;"
								@click.stop="chooseOption(index,indexChild,itemChild.select_type)"
								v-if="itemChild.position==2">{{ansList[index].ansChild[indexChild].ans?ansList[index].ansChild[indexChild].ans.toString():'点击选择'}}</span>
						</span>
					</div>

				</div>
				<div class="summaryWrite">
					<div v-for="(item,index) in addSummaryList" class="summaryWriteOne">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.title">
						</el-input>
						<div>
							<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesDel(index)'>
								<template #reference>
									<button slot="reference" class="del">删除</button>
								</template>
							</el-popconfirm>
							<button class="choosePeople" @click='chooseOther(index)'>选择其他用户评价</button>
						</div>

					</div>
				</div>
				<div class="addSummary" @click="addSummary">
					+会后总结会议效果及评价
				</div>

			</div>
			<div v-show="radio==2">
				<div class="up">
					<div class="btns">
						<button class="upload-demos">上传文件
							<el-upload :action="files2" :multiple='false' :data='fileData3' :on-success='upSuccess'
								:before-upload='onchangemd' class="input">
								<el-button type="primary">上传文件</el-button>
							</el-upload>
						</button><span>支持ppt/pdf/jpg/jpeg/png格式，每个文件不大于10M</span>
					</div>
					<div class="fileList">
						<div v-for="(item,index) in fileList" class="fileListOne">
							<img src="../../assets/fileImg.png" alt="">
							<span>{{item.url2}}</span>
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del(item.id,index)'>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import RadioBox from "@/components/RadioBox.vue";
	import CheckBox from "@/components/CheckBox.vue";
	import OtherBox from "@/components/OtherBox.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	export default {
		name: "SummaryKe",
		data() {
			return {
				name: 'SummaryKe',
				showMask: true,
				radio: 0,
				queList: {
					msg2: {
						title: '',
					}
				},
				ansList: [],
				chooseApprove: [], //问题2单选传回来的值
				addSummaryList: [], //增加的效果评价输入框列表
				chooseArr: [], //选择的问题索引值
				show1: false,
				show2: false,
				show3: false,

				fileList: [],
				files: API_LIST.uploadNew, //图片
				files2: API_LIST.uploadNew2, //文件
				files3: API_LIST.upload_process, //文件
				processList: [], //文件列表
				fileData3: {},
				keyID:1
			}
		},
		components: {
			RadioBox,
			CheckBox,
			OtherBox
		},
		
		mounted() {
			// this.$eventBus.off('CheckChoose')
			// this.$eventBus.off('CheckChoose2')
			// this.$eventBus.off('otherChoose')
			// this.$eventBus.off('close')
			// this.$eventBus.off('allow')
			//单/多选完后传回来的值
			this.$eventBus.on('CheckChooseShaYan', data => {
				console.log(data)
				console.log(this.ansList)
				this.show1 = false
				this.show2 = false
				this.show3 = false
				this.ansList[data.index.ind].ansChild[data.index.indChild].ans = data.choose
			})
			//单选传回来的值
			this.$eventBus.on('CheckChooseShaYan2', data => {
				this.show1 = false;
				this.show2 = false;
				this.show3 = false;
				this.chooseApprove = data.choose;
			})
			//

			this.$eventBus.on('otherChooseShaYan', data => {
				this.show1 = false;
				this.show2 = false;
				this.show3 = false;
				this.addSummaryList[data.index] = {
					id: data.choose[0].id,
					title: data.choose[0].title
				}

			})
			//关闭
			this.$eventBus.on('close', data => {
				this.show1 = false;
				this.show2 = false;
				this.show3 = false;
			})
			//遮罩层
			this.$eventBus.on('allowShaYan', data => {
				this.showMask = !data.allowData
				this.getListInfos()
			})
			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.fileData3 = {
				u_id: localStorage.getItem("u_id"),
				department_meet_id: this.meetID,
				process_id: '',
				type: 2,
				img_type: 1,
				pub_key: 'se',
				file_type: 4,
				submit_type: 2
			}
			// this.getListInfos()
		},
		beforeDestroy() {

			// this.$eventBus.off('CheckChoose')
			// this.$eventBus.off('CheckChoose2')
			// this.$eventBus.off('close')
			// this.$eventBus.off('allow')
		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message: '请先完成会议通知板块内容',
					type: 'warning',
				})
			},
			//获取问题和选项
			getListInfos() {

				this.chooseArr = []
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.memory_se_type1_4, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							this.queList = _d.data.list
							this.chooseApprove = _d.data.list.msg2.answer;
							console.log(55555555, this.chooseApprove.length)
							this.radio = _d.data.list.type2;
							this.addSummaryList = _d.data.list.data2
							_d.data.list.data.forEach((item, index) => {
								this.ansList.push({
									ans: '',
									ansChild: []
								})
								if (item[0].checked == 1) {
									this.chooseArr.push(index)
								} else {
									this.chooseArr.push(999)
								}

								item.forEach((itemChild, indexChild) => {
									//(index)

									// if (itemChild.result.length != 0) {
									this.ansList[index].ansChild.push({
										ans: itemChild.answer
									})
									// }

								})

							})


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//选择问题
			chooseQue(_index) {

				if (this.chooseArr.some(item => {
						return _index == item
					})) {
					this.chooseArr[_index] = 999
				} else {
					this.chooseArr[_index] = _index
				}
			},
			//单选选择
			chooseOption2() {
				this.show1 = true
				this.$eventBus.emit('showRadioBox', {
					ind: 9999,
					indChild: '',
					radioList: this.queList.reco,
					keyID:this.keyID
				})
			},
			//单/多选选择
			chooseOption(_ind, _indChild, _select_type) {


				if (_select_type == 1) {
					this.show2 = true
					this.$eventBus.emit('showCheckBox', {
						ind: _ind,
						indChild: _indChild,
						CheckList: (this.queList.data[_ind])[_indChild].result
					})
				} else if (_select_type == 2) {
					this.show1 = true
					this.$eventBus.emit('showRadioBox', {
						ind: _ind,
						indChild: _indChild,
						radioList: (this.queList.data[_ind])[_indChild].result,
						keyID:this.keyID
					})
				}

			},
			//新增总结
			addSummary() {
				this.addSummaryList.push({
					id: '',
					title: ''
				})
			},
			//删除自己添加的总结
			yesDel(_index) {

				this.addSummaryList.splice(_index, 1)
			},
			//选择其他用户评价
			chooseOther(_ind) {
				this.show3 = true;
				this.$eventBus.emit('showOtherBox', {
					type: 1,
					ind: _ind,
					meetID: this.meetID,
					sortType: 1, // 1为会后总结
					keyID:this.keyID
				})
			},
			//上传文件
			upSuccess(e) {
				if (e.status == 200) {
					ElMessage({
						message: e.msg,
						type: 'success',
					})

					this.fileList.push(e.list)
				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			//删除图片
			del(_id, _ind) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					process_id: _id,
				}

				axios.post(API_LIST.del_process_txt, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.fileList.splice(_ind, 1)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 
			radioChange(e) {
				console.log(e)
				if (e == 2) {
					this.getFileList()
				}
			},
			// 
			getFileList() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				axios.post(API_LIST.sel_txt_4, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.fileList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			}
		}
	}
</script>

<style scoped lang="scss">
	.chooseSpan {
		position: relative;
	}

	.chooseSpan::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		z-index: 100;
		background-color: #000;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	.sheet {
		background-color: #fff;
		padding: 15px 0;
		position: relative;
		box-shadow: 0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;
		}

		.sheetBox {
			width: 1050px;
			margin: 0 auto;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;

				.title {
					display: inline-block;
					width: 160px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box1 {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.summary {
				width: 761px;
				margin: 0 auto;
				padding: 25px 0 0 0;

				p {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
				}

				.notes {
					color: #E30000;
				}

				.random {
					font-size: 14px;
					color: #000;
					margin: 0 0 15px 0;
					cursor: pointer;
					position: relative;
				}

				.random::before {
					position: absolute;
					content: '';
					width: 15px;
					height: 15px;
					left: -25px;
					bottom: 0;
					top: 0;
					margin: auto;
					border: 1px solid #aaa;
					border-radius: 3px;
				}

				.chooseRandom {
					position: relative;
				}

				.chooseRandom::before {
					position: absolute;
					background-color: #1890FF;
				}

				.chooseRandom::after {
					position: absolute;
					left: -19px;
					top: 0;
					bottom: 0;
					margin: auto;
					content: '';
					width: 3px;
					height: 8px;
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					transform: rotate(45deg);
				}

				.summaryWrite {
					display: flex;
					flex-direction: column;
					//align-items:center;
					width: 100%;

					.summaryWriteOne {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;

						.del {
							margin: 15px 0;
							color: #E30000;
							cursor: pointer;
							background-color: #fff;
						}

						.choosePeople {
							color: #1890FF;
							margin: 0 0 0 30px;
							cursor: pointer;
							background-color: #fff;
						}
					}
				}

				.addSummary {
					width: 211px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					text-align: center;
					line-height: 28px;
					margin: 15px auto;
					cursor: pointer;
				}
			}

			.up {
				display: flex;
				flex-direction: column;
				padding: 0 0 0 00px;
				box-sizing: border-box;
				margin: 10px 0;

				.title {
					padding: 15px 0;
					border-bottom: 1px solid #ddd;
				}

				.li {
					display: flex;
					flex-direction: column;
					margin: 0 0 15px 0;

					p {
						margin: 15px 0 0 100px;
					}

					img {
						width: 90px;
						height: 90px;
						margin: 0 10px 10px 0;
					}
				}

				.btns {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 15px 0 0 200px;

					.upload-demos {
						width: 110px;
						height: 37px;
						background: #1890ff;
						color: #fff;
						text-align: center;
						line-height: 37px;
						position: relative;

						.input {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 110px;
							height: 37px;
							opacity: 0;
						}
					}

					span {
						color: #000;
						opacity: 0.65;
						font-size: 14px;
						margin: 0 0 0 10px;
					}
				}

				.fileList {
					display: flex;
					flex-direction: column;
					margin: 15px 0px 0 200px;

					.fileListOne {
						margin: 0 0 10px 0;

						img {
							width: 40px;
							height: 40px;
							margin: 0px 10px 0 0px;
						}

						span {
							margin: 0px 10px 0 0px;
						}

						.icon {
							width: 30px;
							height: 30px;
							cursor: pointer;
						}
					}
				}
			}

			.btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 20px 0;

				//justify-content:center;
				button:nth-child(1) {
					color: #D50000;
					margin: 0 100px 0 280px;
					cursor: pointer;
				}

				button:nth-child(2) {
					width: 97px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					cursor: pointer;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}
	}
</style>
