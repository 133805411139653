<template>
	<div class="hello">
		<div class="head">
			<div class="logo">
				<img src='../assets/logo.png' />
				<span>{{info.user_name}}</span>
			</div>
			<div class="close" @click="quit">
				<img src="../assets/close.png" />
				<span>退出</span>
			</div>
		</div>
	</div>
	<div class='title'>数据查询</div>
	<div class="shai">
		<div class="date">
			<span>拜访时间:</span>
			<el-date-picker v-model="date" type="daterange" range-separator="---" start-placeholder="开始时间"
				end-placeholder="结束时间" :size="size" unlink-panels='false' format="YYYY/MM/DD" value-format="YYYY-MM-DD"
				@change='dateChange' />
		</div>
		<div class="btn">
			<button @click="reset">重置</button>
		</div>
	</div>
	<div class="list">
		<p>应用列表</p>
		<div class="lists">
			<div class="heads">
				<span>编号</span>
				<span>姓名</span>
				<span>拜访单位</span>
				<span>拜访时间</span>
				<span>附件上传</span>
				<span>拜访单状态</span>
				<span>操作</span>
			</div>
			<div class="listOne" v-for="(item,index) in info.list" :class="{hui:index%2==1}">
				<span>{{item.number}}</span>
				<span>{{item.cus_name}}</span>
				<span>{{item.cus_company}}</span>
				<span>{{item.addtime}}</span>
				<span :class="[item.state==1?'green':'red']">{{item.state==1?'已上传':'未上传'}}</span>
				<span :class="[item.state==1?'green':'red']">{{item.state==1?'已完成':item.state==3?'未完成':'未通过'}}</span>
				<span class="look" v-if="item.state!=1" @click="upData(item.state,item.id)">上传</span>
				<span class="look" v-else @click="upData(item.state,item.id)">查看</span>
			</div>
		</div>
		<div class="pages">
			<el-pagination v-model:current-page="page" layout="prev,pager,next,jumper"
				:hide-on-single-page='info.pageNum' :page-count='info.pageNum' @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
	</div>

	</div>
</template>

<script>
	//引入axios
	import axios from 'axios';
	//引入端口配置文件
	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'


	export default {
		name: "HelloWorld",
		props: {
			msg: String,

		},
		data() {
			return {
				info: '', //用户信息
				date: '', //状态，1：已审核，2：待审核，3：未完成，4：未通过，5：待提交
				startTime: '', //开始时间
				endTime: '', //结束时间
				page: 1, //页码
				dataList: [], //数据
			}

		},
		watch: {

		},
		created() {

			if (!localStorage.getItem("u_id")) {
				this.$router.push({
					path: '/',
					query: {

					}
				});
			} else {
				this.getList()
			}
		},
		mounted() {
			this.heights = window.innerHeight - 60 + 'px'

		},
		methods: {
			//获取数据列表
			getList() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					page: this.page,
					start_time: this.startTime,
					end_time: this.endTime,
				}
				let a = qs.stringify(obj)


				axios.post(API_LIST.mah_pc_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.info = _d.data.list;

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//跳页
			jump(_id) {
				if (_id != 4) {
					ElMessage({
						message: '该功能暂未开放',
						type: 'warning',
					})
					return
				}
				this.$router.push({
					path: '/Home',
					query: {
						id: _id,
					}
				});

			},
			//更改日期
			dateChange(e) {

				this.startTime = e[0]
				this.endTime = e[1]
				this.page = 1;
				this.getList()
			},
			//重置
			reset() {
				this.date = '';
				this.startTime = '';
				this.endTime = '';
				this.page = 1;
				this.getList()
			},
			//点击跳页
			handleSizeChange() {

				this.getList()
			},
			//输入跳页
			handleCurrentChange() {

				this.getList()
			},
			//跳转到上传页
			upData(_state, _id) {
				this.$router.push({
					path: '/UpDataMah',
					query: {
						department_meet_id: _id,
					}
				});
			},
			//退出
			quit() {
				window.sessionStorage.clear();
				this.$router.push({
					path: '/',
					query: {

					}
				});
			},
		}
	};
</script>

<!--Add"scoped"attributetolimitCSStothiscomponentonly-->
<style scoped lang="scss">
	.mask {
		width: 100%;
		height: 100%;
		background-color: #fff;
		opacity: 0.5;
		left: 0;
		top: 0;
		position: absolute;
	}

	.head {
		height: 48px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 1px solid #f0f2f5;

		.logo,
		.close {
			img {
				height: 26px;
			}

			span {
				margin: 0 25px 0 10px;
				font-size: 14px;
			}
		}

		.close {
			span {
				color: #0073FF;
			}
		}

		.close:hover {
			cursor: pointer;
		}
	}

	.title {
		width: 100%;
		padding: 30px 150px;
		box-sizing: border-box;
		font-size: 20px;
		font-weight: 800;
		border-bottom: 20px solid #f0f2f5;
	}

	.shai {
		width: 100%;
		padding: 20px 180px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 15px solid #f0f2f5;

		.date {
			margin: 0 100px 0 0;

			span {
				margin: 0 15px 0 0;
			}
		}

		.btn {
			width: 65px;
			height: 32px;

			button {
				cursor: pointer;
				width: 100%;
				height: 100%;
				background: #1890ff;
				border-radius: 2px;
				color: #fff;
			}
		}
	}

	.list {
		width: 100%;
		display: flex;
		flex-direction: column;

		p {
			padding: 15px 150px;
			font-size: 16px;
			color: #000000;
			font-weight: 800;
		}

		.lists {
			padding: 25px 150px;

			.heads,
			.listOne {
				background-color: #fafafa;
				padding: 20px 60px;
				box-sizing: border-box;
				display: flex;
				flex-direction: row;

				.look {
					color: #1890FF;
				}

				span:nth-child(1) {
					width: 200px;
				}

				span:nth-child(2) {
					width: 100px;
				}

				span:nth-child(3) {
					width: 150px;
				}

				span:nth-child(4) {
					width: 200px;
				}

				span:nth-child(5) {
					width: 100px;
				}

				span:nth-child(6) {
					width: 100px;
				}

				span:nth-child(7) {
					width: 100px;
				}

				span:nth-child(8) {
					width: 100px;
				}

				span:nth-child(9) {
					width: 100px;
				}

				span:hover {
					cursor: pointer;
				}

				.green,
				.red {
					position: relative;
				}

				.green::before {
					position: absolute;
					top: 0;
					left: 0px;
					bottom: 0;
					margin: auto;
					content: '';
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: #52C41A;
				}

				.red::before {
					position: absolute;
					top: 0;
					left: 0px;
					bottom: 0;
					margin: auto;
					content: '';
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: #F5222D;
				}
			}

			.hui {
				background-color: #fff;
			}

			.listOne {
				span:nth-child(5) {
					width: 100px;
					padding: 0 0 0 10px;
					box-sizing: border-box;
				}

				span:nth-child(6) {
					width: 100px;
					padding: 0 0 0 10px;
					box-sizing: border-box;
				}

				//span:nth-child(7){
				// width:100px;
				// padding:00010px;
				// box-sizing:border-box;
				//}
				//span:nth-child(8){
				// width:100px;
				// padding:00010px;
				// box-sizing:border-box;
				//}
			}



		}
	}

	.pages {
		padding: 10px 350px;
		text-align: left;
	}
</style>
