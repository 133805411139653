<template>
	<div class="hello">
		<div class="head">
			<div class="logo">
				<img src='../assets/logo.png' />
				<span>{{info.user_name}}</span>
			</div>
			<div class="close" @click="quit">
				<img src="../assets/close.png" />
				<span>退出</span>
			</div>
		</div>
		<div class="names">
			<img src="../assets/logo.png" alt="">
			<div>
				<span class="title">您好，{{info.user_name}} ，祝您开心每一天！</span>
				<span class="gongsi">{{info.company_name}}</span>
			</div>
		</div>
		<div class="list">
			<div v-for='item in menuList' class="bigSort">
				<div v-for='(itemChild,index) in item' :class="{mask:itemChild.pc_state != 1}" class='smallSort'
					alt='dada' @click='jump(itemChild.id,itemChild.pc_state)'>
					<div>
						<img :src="require('../assets/'+iconList[index])" alt="">
						<span class='name'>{{itemChild.name}}</span>
					</div>
					<p>
						{{itemChild.summary}}
					</p>
					<span class="gongsi">{{itemChild.company_name}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'


	export default {
		name: "HelloWorld",
		props: {
			msg: String,

		},
		data() {
			return {
				iconList: ['icon_1.png', 'icon_2.png', 'icon_3.png', 'icon_4.png',
					'icon_5.png', 'icon_6.png', 'icon_2.png', 'icon_4.png'
				], // 图标数组
				info: '', // 用户信息
				menuList: '', // 功能列表
				ind: 4,
			}

		},
		watch: {

		},
		created() {


			if (!localStorage.getItem("u_id")) {
				this.$router.push({
					path: '/',
					query: {

					}
				});
			} else {
				let obj = {
					u_id: localStorage.getItem("u_id")
				}
				let a = qs.stringify(obj)
				console.log(a)

				axios.post(API_LIST.pc_fun, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)

						if (_d.data.status == 200) {
							this.info = _d.data.list;
							this.menuList = this.spArr(_d.data.list.list, 8);
							console.log(this.menuList);
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		},
		mounted() {
			this.heights = window.innerHeight - 60 + 'px'
			console.log(this.heights)







		},
		methods: {
			
			// 退出
			quit() {
				window.sessionStorage.clear();
				this.$router.push({
					path: '/',
					query: {

					}
				});
			},
			// 跳页
			jump(_id, _pc_state) {
				if (_pc_state != 1) {
					ElMessage({
						message: '该功能暂未开放',
						type: 'warning',
					})
					return
				}
				if (_id == 4) {
					this.$router.push({
						path: '/Hui',
						query: {
							id: _id,
						}
					});
				} else if (_id == 11) {
					this.$router.push({
						path: '/DownLiu',
						query: {
							id: _id,
						}
					});
				} else if (_id == 15) {
					this.$router.push({
						path: '/MAH',
						query: {
							id: _id,
						}
					});
				} else if (_id == 2) {
					this.$router.push({
						path: '/Promotion',
						query: {
							id: _id,
						}
					});
				} else {
					ElMessage({
						message: '该功能暂未开放',
						type: 'warning',
					})
					return

				}


			},
			// 数组切片
			spArr(arr, num) { //arr是你要分割的数组，num是以几个为一组
				let newArr = [] //首先创建一个新的空数组。用来存放分割好的数组
				for (let i = 0; i < arr.length;) { //注意：这里与for循环不太一样的是，没有i++
					newArr.push(arr.slice(i, i += num));
				}
				return newArr
			},

		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.mask {
		width: 100%;
		height: 100%;
		background-color: #fff;
		opacity: 0.5;
		left: 0;
		top: 0;
		position: absolute;
	}

	.hello {
		background-color: #fff;
	}

	.head {
		height: 48px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 15px solid #f0f2f5;

		.logo,
		.close {
			img {
				height: 26px;
			}

			span {
				margin: 0 25px 0 10px;
				font-size: 14px;
			}
		}

		.close {
			span {
				color: #0073FF;
			}
		}

		.close:hover {
			cursor: pointer;
		}
	}

	.names {
		height: 150px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		// justify-content: flex-start;
		border-bottom: 10px solid #f0f2f5;
		padding: 30px 0 50px 150px;
		box-sizing: border-box;

		img {
			height: 53px;
		}

		div {
			margin: 0 20px;
			height: 53px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			.title {
				font-size: 20px;
				font-weight: 800;
			}

			.gongsi {
				font-size: 14px;
				color: #000;
				opacity: 0.45;
			}
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		padding: 0 0 100px 0;

		.bigSort {
			display: flex;
			flex-direction: row;
			padding: 0 150px;
			box-sizing: border-box;
			border-bottom: 10px solid #f0f2f5;
			// justify-content: space-around;
			flex-wrap: wrap;

			.smallSort {
				width: 25%;
				height: 200px;
				box-sizing: border-box;
				padding: 15px;
				border: 1px solid #eee;
				justify-content: space-between;
				position: relative;
				cursor: pointer;

				div {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 10px 0 0 0;
					box-sizing: border-box;

					img {
						width: 24px;
					}

					span {
						font-size: 14px;
						margin: 0 0 0 10px;
					}
				}

				p {
					color: #000;
					opacity: 0.75;
					margin: 20px 0;
				}

				.gongsi {
					color: #000;
					opacity: 0.75;
				}

			}
		}
	}
</style>
